.searchcomboinput {
  align-items: center;
  background-color: var(--card_background_color);
  border: 2px solid var(--high_contrast_border_color_subtle);
  border-radius: $radius_big;
  box-shadow: var(--elevation_shadow_1);
  box-sizing: border-box;
  color: var(--app_font_color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 $spacing_big 0 0;
  position: relative;

  @media screen and (max-width: $large_screen_width) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:focus-within {
    border-color: var(--csis_color);

    .input {
      border-color: transparent;
    }
  }

  &:hover {
    border-color: var(--csis_color);

    .input {
      border-color: transparent;
    }
  }

  &__dropdowns,
  &__extra-buttons {
    @media screen and (max-width: $large_screen_width) {
      display: none;
    }
  }

  &__dropdowns-alt {
    background-color: var(--card_background_color);
    border: var(--high_contrast_border_width) solid
      var(--high_contrast_border_color_subtle);
    border-radius: 0 0 $radius_big $radius_big;
    box-shadow: var(--elevation_shadow_1);
    box-sizing: border-box;
    display: none;
    padding: $spacing_big $spacing_large;
    width: 100%;

    @media screen and (max-width: $large_screen_width) {
      display: flex;
    }
    @media (max-width: $mobile_screen_width) {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  &__filters {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-top: $spacing_big;
  }

  &__advanced-search-btn {
    @media (max-width: $mobile_screen_width) {
      display: none;
    }
  }

  &__advanced-search {
    border-radius: $radius_big;
    box-shadow: var(--elevation_shadow_2);
    margin-top: $spacing_small;
    min-width: 100%;
    position: absolute;
    z-index: $z_index_above_99;

    &__latest-searches {
      width: 300px;
    }

    &__info {
      border-left: 1px solid var(--disabled_font_color);
      padding-left: $spacing_large;
    }

    &__option {
      color: var(--csis_color);
      cursor: pointer;
      font-size: $text_small;
      font-weight: $text_semibold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__autocomplete {
    box-sizing: border-box;
    margin-top: $spacing_small;

    .card {
      box-sizing: border-box;
      max-height: 300px;
      overflow-y: auto;
    }

    &__option {
      cursor: pointer;
      padding: $spacing_small;
      width: 100%;

      &:hover {
        background: var(--sidebar_item_selected_color);
      }
    }
  }
}

.searchcomboinput__container {
  min-width: 100%;
  position: relative;
  @media (max-width: $mobile_screen_width) {
    max-width: 100%;
  }

  &--multiline,
  &--alt-toolbar-visible {
    .searchcomboinput {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .searchcomboinput__dropdowns {
      display: none;
    }

    .searchcomboinput__dropdowns-alt {
      display: flex;
    }
  }
}
