.mitreattackmatrix {
  box-sizing: border-box;
  display: flex;
  overflow: scroll;

  &__container {
    display: flex;
    height: 95vh;

    &--small {
      overflow: scroll;
      width: 75%;
    }
  }

  &__sidebar {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .card-base;

    display: flex;
    flex-grow: 1;
    height: 94vh;
    min-width: 400px;
    overflow: scroll;
    padding: $spacing_large $spacing_big;
    position: relative;
    width: 400px;

    &__close-btn {
      position: absolute;
      right: $spacing_big;
      top: $spacing_big;
    }

    &__title {
      color: var(--csis_color);
      font-size: $text_large;
      font-weight: $text_semibold;
      max-width: 80%;
    }

    &__description {
      text-align: justify;
    }

    .list {
      flex-grow: unset;
    }

    .accordion {
      padding-bottom: $spacing_big;
    }
  }

  &__tactic {
    box-sizing: border-box;
    width: 190px;

    &__title {
      align-items: flex-end;
      background: var(--app_background_color);
      display: flex;
      font-weight: $text_semibold;
      height: 37px;
      padding-bottom: $spacing_small;
      position: sticky;
      top: 0;
    }
  }

  &__techniques {
    overflow: hidden;
    padding-bottom: $spacing_small;
  }

  &__technique {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .card-base;

    align-items: flex-start;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: $text_small;
    height: 80px;
    justify-content: center;
    overflow: hidden;
    padding: 0 $spacing_big;
    width: 100%;

    &__count {
      font-size: $text_big;
      font-weight: $text_semibold;
    }

    &:hover {
      border: 2px dotted var(--orange_color);
      padding: calc($spacing_big - 2px) !important;
    }

    &:focus {
      border: 2px solid var(--orange_color);
      outline: 0;
      padding: calc($spacing_big - 2px) !important;
    }

    &--selected {
      border: 2px solid var(--orange_color);
      padding: calc($spacing_big - 2px) !important;
    }
  }

  &--with-levels {
    .mitreattackmatrix__technique {
      height: 110px;
      justify-content: flex-start;
      padding: $spacing_normal;

      &:hover {
        padding: calc($spacing_normal - 2px) !important;
      }

      &:focus {
        padding: calc($spacing_normal - 2px) !important;
      }

      &--selected {
        padding: calc($spacing_normal - 2px) !important;
      }
    }

    .mitreattackmatrix__technique--lvl1 {
      background: var(--mitre_lvl1_color);
    }

    .mitreattackmatrix__technique--lvl2 {
      background: var(--mitre_lvl2_color);
    }

    .mitreattackmatrix__technique--lvl3 {
      background: var(--mitre_lvl3_color);
      color: var(--app_background_color);
    }

    .mitreattackmatrix__technique--lvl4 {
      background: var(--mitre_lvl4_color);
      color: var(--app_background_color);
    }
  }

  &--compact {
    .mitreattackmatrix__tactic {
      width: 120px;

      &__title {
        font-size: $text_small;
      }
    }

    .mitreattackmatrix__technique {
      height: 60px;
      padding: $spacing_normal;

      &:hover {
        padding: calc($spacing_normal - 2px) !important;
      }

      &:focus {
        padding: calc($spacing_normal - 2px) !important;
      }

      &--selected {
        padding: calc($spacing_normal - 2px) !important;
      }
    }

    &.mitreattackmatrix--with-levels {
      .mitreattackmatrix__technique {
        height: 90px;
      }
    }
  }
}
