.button-icon {
  align-items: center;
  border: $border_width_normal solid;
  border-radius: $radius_small;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 0;

  &--circle {
    border-radius: $radius_circle;
  }

  &--spacing-tiny {
    padding: $spacing_tiny;
  }

  &--spacing-small {
    padding: $spacing_small;
  }

  &--spacing-normal {
    padding: $spacing_normal;
  }

  &--spacing-big {
    padding: $spacing_big;
  }

  &--spacing-large {
    padding: $spacing_large;
  }

  &--fixed-bottom {
    bottom: $spacing_large;
    box-shadow: var(--elevation_shadow_1);
    position: fixed;
    right: $spacing_large;
    z-index: $z_index_above_99;
  }
}

.button-icon--primary {
  background-color: var(--csis_color);
  border-color: var(--csis_color);

  &:hover {
    background-color: var(--csis_color_lighter);
    border-color: var(--csis_color_lighter);
  }

  &:active {
    background-color: var(--csis_color_darker);
    border-color: var(--csis_color_darker);
  }

  .icon {
    background-color: var(--app_background_color);
  }

  &.button-icon--blue {
    background-color: var(--blue_color);
    border-color: var(--blue_color);

    &:hover {
      background-color: var(--blue_color_lighter);
      border-color: var(--blue_color_lighter);
    }

    &:active {
      background-color: var(--blue_color_darker);
      border-color: var(--blue_color_darker);
    }

    .icon {
      background-color: var(--app_background_color);
    }
  }

  &.button-icon--red {
    background-color: var(--red_color);
    border-color: var(--red_color);

    &:hover {
      background-color: var(--red_color_lighter);
      border-color: var(--red_color_lighter);
    }

    &:active {
      background-color: var(--red_color_darker);
      border-color: var(--red_color_darker);
    }

    .icon {
      background-color: var(--app_background_color);
    }
  }

  &.button-icon--grey {
    background-color: var(--grey-alt_color);
    border-color: var(--grey-alt_color);
    color: var(--app_font_color);

    &:hover {
      background-color: var(--grey-alt_color_lighter);
      border-color: var(--grey-alt_color_lighter);
    }

    &:active {
      background-color: var(--grey-alt_color_darker);
      border-color: var(--grey-alt_color_darker);
    }

    .icon {
      background-color: var(--app_font_color);
    }
  }

  &.button-icon--yellow {
    background-color: var(--yellow_color);
    border-color: var(--yellow_color);

    &:hover {
      background-color: var(--yellow_color_lighter);
      border-color: var(--yellow_color_lighter);
    }

    &:active {
      background-color: var(--yellow_color_darker);
      border-color: var(--yellow_color_darker);
    }

    .icon {
      background-color: var(--app_background_color);
    }
  }

  &.button-icon--secondary {
    background-color: var(--app_font_color_secondary);
    border-color: var(--app_font_color_secondary);

    &:hover {
      background-color: var(--app_font_color_secondary);
      border-color: var(--app_font_color_secondary);
    }

    &:active {
      background-color: var(--app_font_color_secondary);
      border-color: var(--app_font_color_secondary);
    }

    .icon {
      background-color: var(--app_background_color);
    }
  }

  &.button-icon--disabled {
    background-color: var(--disabled_background_color);
    border-color: var(--disabled_background_color);
    cursor: not-allowed;
    pointer-events: none;

    .icon {
      background-color: var(--disabled_font_color);
    }
  }
}

.button-icon--text {
  background-color: transparent;
  border-color: transparent;

  .icon {
    background-color: var(--csis_color);
  }

  &:hover {
    background-color: var(--csis_color_transparent);
  }

  &.button-icon--blue {
    .icon {
      background-color: var(--blue_color);
    }

    &:hover {
      background-color: var(--blue_color_transparent);
    }
  }

  &.button-icon--red {
    .icon {
      background-color: var(--red_color);
    }

    &:hover {
      background-color: var(--red_color_transparent);
    }
  }

  &.button-icon--grey {
    .icon {
      background-color: var(--grey_color);
    }

    &:hover {
      background-color: var(--grey_color_transparent);
    }
  }

  &.button-icon--secondary {
    .icon {
      background-color: var(--app_font_color_secondary);
    }

    &:hover {
      background-color: var(--grey_color_transparent);
    }
  }

  &.button-icon--yellow {
    .icon {
      background-color: var(--yellow_color);
    }

    &:hover {
      background-color: var(--yellow_color_transparent);
    }
  }

  &.button-icon--disabled {
    cursor: not-allowed;
    pointer-events: none;

    .icon {
      background-color: var(--disabled_font_color);
    }
  }
}
