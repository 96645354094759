.severity-frequency-selector {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  &__body {
    box-sizing: border-box;
    display: flex;
    padding: $spacing_normal $spacing_big;
    width: 100%;
  }

  &__label {
    color: var(--app_font_color_secondary);
    font-weight: $text_semibold;
    width: 100px;
  }
}
