.table {
  border: 0;
  border-spacing: 0;
  line-height: $text_normal;
  overflow: hidden;

  &__header {
    background-color: transparent;

    font-weight: $text_semibold;
    position: relative;
    text-transform: uppercase;

    &__settings-btn {
      position: absolute;
      right: $spacing_normal;
      top: $spacing_normal;
    }

    .cell {
      font-weight: $text_semibold;
      text-align: left;
      vertical-align: middle;
      white-space: nowrap;

      &:focus-visible {
        outline-offset: -4px;
      }

      &--centered {
        text-align: center;
      }

      &--right-aligned {
        text-align: right;
      }

      &--last-with-extra-space {
        padding-right: $spacing_gigantic !important;
      }

      span {
        @extend %limit-text-width;
        display: inline-block;
        vertical-align: middle;
      }

      .table__sort-icon {
        .icon {
          background-color: var(--app_font_color);
        }

        &--hoverable {
          visibility: hidden;

          .icon {
            background-color: var(--app_font_color);
          }
        }
      }

      &:hover {
        .table__sort-icon--hoverable {
          visibility: visible;
        }
      }

      &--sortable {
        cursor: pointer;
      }
    }
  }

  &__row {
    &--clickable {
      cursor: pointer;

      &:hover {
        background-color: var(--table_solid_color_hover);
      }

      &:focus-visible {
        outline-offset: -3px !important;
      }
    }
  }

  &__bookmark-icon {
    display: inline-flex;
    vertical-align: middle;

    &:hover {
      .icon {
        background-color: var(--csis_color_lighter);
      }
    }
  }

  &__bookmark-text {
    vertical-align: middle;
  }

  &--full-width {
    width: 100%;
  }

  &__subrow-element {
    background-color: var(--grey-alt_color_transparent);
    box-shadow:
      inset 0 11px 4px -10px var(--elevation_shadow_color),
      inset 0 -11px 4px -10px var(--elevation_shadow_color);
    padding: $spacing_big;
  }

  .cell {
    @extend %limit-text-width;
    font-size: $text_normal;
    padding: $spacing_normal $spacing_big;
    vertical-align: middle;

    &--centered {
      text-align: center;
    }

    &--right-aligned {
      text-align: right;
    }

    &--nowrap {
      white-space: nowrap;
    }
  }

  &__row {
    .cell {
      border-bottom: 1px solid var(--grey-alt_color_semi-transparent);
    }
  }

  .checkbox-cell {
    border-bottom: 1px solid var(--disabled_font_color);
    max-width: $spacing_huge;
    padding: 0;
    vertical-align: middle;
  }

  &__subrow-arrow-cell {
    padding-left: $spacing_normal !important;
    padding-right: 0 !important;
  }

  &--borderless {
    .cell {
      border-bottom: 0;
    }
  }

  &--solid {
    background-color: var(--table_solid_color);
    border-radius: $radius_big;

    &.table--radius-normal {
      border-radius: $radius_normal;
    }

    &.table--radius-small {
      border-radius: $radius_small;
    }

    .cell {
      border-bottom: 0;
    }

    .checkbox-cell {
      border-bottom: 0;
    }

    &.table--zebra {
      .table__header {
        background-color: var(--table_solid_color_alt);

        &:hover {
          background-color: var(--csis_color_transparent);
        }
      }

      .table__row:nth-child(even) {
        background-color: var(--table_solid_color_alt);
      }

      .table__row:hover {
        background-color: var(--table_solid_color_hover);
      }
    }
  }

  &--no-cell-max-width {
    .cell {
      max-width: none !important;
    }
  }

  &--small {
    .cell {
      font-size: $text_small;
      padding: $spacing_small $spacing_normal;
    }

    .table__header__settings-btn {
      right: $spacing_tiny;
      top: $spacing_tiny;
    }
  }

  &--large {
    .cell {
      font-size: $text_normal;
      padding: $spacing_big $spacing_big;
    }

    .table__header__settings-btn {
      right: $spacing_normal;
      top: calc($text_normal - 3px);
    }
  }

  &__empty-placeholder {
    align-items: center;
    background-color: var(--table_solid_color);
    display: flex;
    justify-content: center;
    padding: $spacing_huge;
  }
}
