.new-emergency-case-dialog {
  &__btn {
    display: flex;
    @media (max-width: $mobile_screen_width) {
      display: none;
    }
  }

  &__btn--mobile {
    display: none;
    @media (max-width: $mobile_screen_width) {
      display: flex;
    }
  }
}
