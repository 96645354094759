.users-access-page {
  &__card {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .card-base;
    height: 382px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $spacing_big;
  }

  &__esc-list__header {
    background-color: var(--table_solid_color_alt);
    border-radius: $radius_small;
    font-size: $text_normal;
    font-weight: $text_semibold;
    line-height: $text_normal;
    padding: $spacing_big $spacing_big;
  }

  &__esc-list__column {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__esc-list__priority-col {
    width: 105px;
  }

  &__esc-list__name-col {
    padding-left: $spacing_big;
    width: 150px;
  }
}
