.global-search {
  &__container {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__contents {
    width: 1000px;
  }

  &__search-result {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: $spacing_normal 0;
    width: 100%;

    &:hover {
      .global-search__title {
        text-decoration: underline;
      }
    }
  }

  &__title {
    max-width: 70ch;
  }

  &__show-more {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
