@import "@csis.com/components/src/core-styles/constants";
@import "@csis.com/components/src/core-styles/utilityclasses";
@import "@csis.com/components/src/core-styles/components";
@import "tip-components";
@import "fonts";

* {
  font-family: "Averta", Arial, sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: var(--app_background_gradient_color);
  color: var(--app_font_color);
  font-size: $text_normal;
  font-weight: $text_regular;
  height: 100%;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: $text_gigantic;
  font-weight: $text_regular;
  line-height: 1.3;
  margin: 0;
}

h2 {
  font-size: $text_huge;
  font-weight: $text_regular;
  line-height: 1.2;
  margin: 0;
}

h3 {
  font-size: $text_large;
  font-weight: $text_regular;
  margin: 0;
}

h4 {
  font-size: $text_big;
  font-weight: $text_regular;
}

h5 {
  font-size: $text_normal;
  font-weight: $text_regular;
}

h6 {
  font-size: $text_small;
  font-weight: $text_regular;
}

#app {
  height: 100%;
}

*:focus {
  outline: none;
}

button:focus-visible,
label:focus-visible,
div:focus-visible,
th:focus-visible,
tr:focus-visible,
a:focus-visible {
  outline-color: var(--focus_color);
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 2px;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 16px;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: $text_small;
  font-weight: $text_semibold;
  padding: 0;
}

strong {
  font-weight: $text_semibold;
}
