/* stylelint-disable scss/at-extend-no-missing-placeholder */
.datepicker {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  .dropdown__header {
    text-transform: uppercase;
  }

  &__calendarview {
    background: var(--card_background_color);
    flex-grow: 1;
    padding: $spacing_big;
    width: 260px;
    @media (max-width: $mobile_screen_width) {
      box-sizing: border-box;
      width: 100%;
    }

    &--with-range {
      border-left: 1px solid var(--disabled_font_color);
      min-width: 370px;
      @media (max-width: $mobile_screen_width) {
        border-left: 0;
        min-width: 100%;
      }
    }
  }

  .dropdown__header {
    padding: $spacing_small 0;
  }

  &--shadow {
    .dropdown__header {
      background-color: var(--dropdown_background_color);
      border: 2px solid transparent;
      box-shadow: var(--elevation_shadow_1);
      color: var(--app_font_color);
      padding: $spacing_big;
    }

    .dropdown__header:hover {
      border: 2px solid var(--csis_color);
      color: var(--app_font_color);
    }

    .dropdown__header .icon {
      background-color: var(--app_font_color);
    }

    &.datepicker--small {
      .dropdown__header {
        padding: $spacing_normal;
      }
    }
  }

  &--full-width {
    width: 100%;
  }

  .listmenu {
    @media (max-width: $mobile_screen_width) {
      flex-direction: column !important;
    }
  }
}

// overwrite styles
.datepicker__calendarview--csis .react-datepicker {
  background: transparent;
  border: 0;
  border-radius: $radius_normal;
  overflow: hidden;

  &__header {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--disabled_font_color);
    border-radius: 0;

    &:not(.react-datepicker__header--has-time-select) {
      border-radius: 0;
    }
  }

  &__year-dropdown-container--scroll {
    color: var(--csis_color);
    font-size: $text_small;
    font-weight: $text_semibold;
    margin-top: $spacing_small;

    &:hover {
      color: var(--csis_color_lighter);

      .react-datepicker__year-read-view--down-arrow {
        border-color: var(--csis_color_lighter) !important;
      }
    }
  }

  &__year-read-view--down-arrow {
    border-color: var(--csis_color) !important;
    border-width: 2px 2px 0 0;
    height: 4px;
    right: -13px;
    top: 7px;
    width: 4px;

    &:hover {
      border-color: var(--csis_color_lighter) !important;
    }
  }

  &__year-dropdown--scrollable {
    @extend .card-base;
    border: 0;
  }

  &__year-option {
    border-radius: $radius_small;
    color: var(--app_font_color);
    padding-bottom: $spacing_small;
    padding-top: $spacing_small;

    &:hover {
      background-color: var(--sidebar_item_selected_color);
    }
  }

  &__year-option--selected_year {
    background-color: var(--sidebar_item_selected_color);
  }

  &__year-option--selected {
    display: none;
  }

  &__navigation,
  &__current-month,
  &__day-name {
    color: var(--app_font_color);
    font-weight: $text_semibold;
  }

  &__navigation-icon::before {
    border-color: var(--app_font_color) !important;
    border-width: 2px 2px 0 0 !important;
  }

  &__day {
    border: 1px solid transparent;
    color: var(--app_font_color);
    font-size: $text_small;
    font-weight: $text_regular;
    margin: 0.246rem;

    &:hover {
      background-color: var(--csis_color_transparent);
      border: 1px solid var(--high_contrast_border_color);
    }

    &--today {
      color: var(--csis_color);
      font-weight: $text_semibold;
    }

    &--selected {
      background-color: var(--csis_color_transparent);
      border: 1px solid var(--high_contrast_border_color);
      color: var(--csis_color);
      font-weight: $text_semibold;

      &:hover {
        background-color: var(--csis_color_lighter);
        color: var(--app_background_color);
        font-weight: $text_semibold;
      }
    }

    &--in-range {
      background-color: var(--csis_color_transparent);
      border: 1px solid var(--high_contrast_border_color);
      color: var(--csis_color);
      font-weight: $text_semibold;

      &:hover {
        background-color: var(--csis_color_lighter);
        color: var(--app_background_color);
        font-weight: $text_semibold;
      }
    }

    &--in-selecting-range {
      background-color: var(--csis_color_transparent) !important;
      color: var(--csis_color) !important;

      &:hover {
        background-color: var(--csis_color_lighter) !important;
        color: var(--app_background_color) !important;
        font-weight: $text_semibold !important;
      }
    }

    &--keyboard-selected {
      background-color: var(--csis_color_transparent);
      color: var(--csis_color);
    }
  }

  &__month--selecting-range .react-datepicker__day--in-range {
    background-color: var(--csis_color_transparent);
    color: var(--csis_color);
    font-weight: $text_semibold;

    &:hover {
      background-color: var(--csis_color_lighter);
      color: var(--app_background_color);
      font-weight: $text_semibold;
    }
  }

  &__day--disabled {
    color: var(--disabled_font_color);

    &:hover {
      background-color: transparent;
    }
  }
}
