.news {
  position: relative;

  &__number {
    pointer-events: none;
    position: absolute;
    right: -7px;
    top: 2px;
  }

  &__single-news-item {
    border-radius: $radius_normal;
    padding: $spacing_normal;
  }

  &__circle-new {
    background-color: var(--csis_color);
    border-radius: $radius_circle;
    display: flex;
    height: 10px;
    width: 10px;

    &--hidden {
      visibility: hidden;
    }
  }

  &__dialog-body {
    max-height: 70vh;
    overflow-y: auto;
  }
}
