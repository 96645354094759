.badge {
  align-items: center;
  border-radius: $radius_big;
  box-sizing: border-box;
  color: var(--app_background_color);
  display: flex;
  font-weight: $text_semibold;
  height: 24px;
  max-width: 200px;
  overflow: hidden;
  padding: 0 $spacing_normal;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--csis {
    background-color: var(--csis_color);
  }

  &--red {
    background-color: var(--red_color);
  }
}
