.icon {
  background: var(--csis_color);
  display: flex;
  flex-shrink: 0;
  height: $text_normal;
  width: $text_normal;

  &--inline {
    display: inline-flex;
  }

  &--tiny {
    height: $text_tiny;
    width: $text_tiny;
  }

  &--small {
    height: $text_small;
    width: $text_small;
  }

  &--normal {
    height: $text_normal;
    width: $text_normal;
  }

  &--big {
    height: $text_big;
    width: $text_big;
  }

  &--large {
    height: $text_large;
    width: $text_large;
  }

  &--huge {
    height: $text_huge;
    width: $text_huge;
  }

  &--gigantic {
    height: $text_gigantic;
    width: $text_gigantic;
  }
  // try to not use the colors directly to the icon
  // prefer setting it from the parent especially when there
  // are interactive events like hover etc.
  // only use these in static icons (so not icon buttons,menu icons etc)
  &--red {
    background-color: var(--red_color);
  }

  &--blue {
    background-color: var(--blue_color);
  }

  &--green {
    background-color: var(--green_color);
  }

  &--grey {
    background-color: var(--grey_color);
  }

  &--grey-alt {
    background-color: var(--grey-alt_color);
  }

  &--secondary {
    background-color: var(--app_font_color_secondary);
  }

  &--orange {
    background-color: var(--orange_color);
  }

  &--transparent {
    background-color: transparent;
  }
}
