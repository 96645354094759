.droparea {
  border: 2px dashed transparent;
  border-radius: $radius_small;
  box-sizing: border-box;
  display: flex;

  &--inline {
    display: inline-flex;
  }

  &--active-dragging {
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px dashed var(--grey-alt_color);
    padding: $spacing_normal;
  }
}
