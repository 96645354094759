.inputfile {
  align-items: flex-start;
  border: 0;
  flex-direction: column;
  justify-self: start;
  max-width: 40ch;
  padding: 0;

  label {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  input {
    display: none;
  }

  input {
    padding: 0;
  }

  &__button {
    background-color: var(--disabled_background_color);
    border: 1px solid var(--disabled_background_color);
    border-radius: $radius_tiny;
    box-sizing: border-box;
    color: var(--app_font_color);
    cursor: pointer;
    font-size: $text_small;
    margin-right: $spacing_normal;
    padding: $spacing_small $spacing_normal;
  }

  &__button:hover {
    background-color: var(--disabled_background_color);
  }

  &__text {
    font-size: $text_small;
    font-weight: $text_semibold;
  }

  &__uploaded-file {
    align-items: center;
    display: inline-flex;
    font-size: $text_tiny;
    margin-right: $spacing_normal;
  }

  &__subtext {
    font-size: $text_small;
    margin-top: $spacing_normal;
  }
}
