/* stylelint-disable max-nesting-depth */
$gridspans: (
  1: 8.333333%,
  2: 16.666667%,
  3: 25%,
  4: 33.333333%,
  5: 41.666667%,
  6: 50%,
  7: 58.333333%,
  8: 66.666667%,
  9: 75%,
  10: 83.333333%,
  11: 91.666667%,
  12: 100%,
);

.grid {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  min-width: 100%;

  @each $gapKey, $spacing in $spacings {
    &--gutter-#{$gapKey} {
      min-width: calc(100% + #{$spacing});
    }
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-space-between {
    justify-content: space-between;
  }

  @each $gapKey, $spacing in $spacings {
    &--gutter-#{$gapKey} {
      margin-left: calc(calc($spacing / 2) * -1) !important;

      > .griditem {
        box-sizing: border-box;
        flex-grow: 1;
        padding: 0 calc($spacing / 2) 0;

        @each $spanKey, $span in $gridspans {
          &--span-#{$spanKey} {
            flex: 0 0 $span;
            max-width: $span;
          }
        }

        @each $screen_width_key, $screen_width in $screen_widths {
          &--size-#{$screen_width_key} {
            @media screen and (max-width: #{$screen_width}) {
              flex: none;
              max-width: none;
              padding-bottom: $spacing;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
