.inputpin {
  display: flex;

  input {
    background-color: var(--card_background_color);
    border: 2px solid transparent;
    border-radius: $radius_small;
    box-shadow: var(--elevation_shadow_1);
    box-sizing: border-box;
    color: var(--app_font_color);
    font-size: $text_normal;
    height: 45px;
    margin-left: $spacing_small;
    margin-right: $spacing_small;
    padding: $spacing_normal;
    text-align: center;
    width: 38px;

    &:focus {
      border-color: var(--csis_color_darker);
    }
  }

  &__password-show {
    display: none;
  }
}
