.pacman {
  position: relative;

  &__top {
    animation: spin1 0.5s infinite linear;
    background-color: var(--disabled_font_color);
    border-radius: 100px 100px 0 0;
    height: 100px;
    width: 200px;
  }

  &__bottom {
    animation: spin2 0.5s infinite linear;
    background-color: var(--disabled_font_color);
    border-radius: 0 0 100px 100px;
    height: 100px;
    width: 200px;
  }

  &__feed {
    animation: eat 1s linear infinite;
    border-radius: 100%;
    height: 35px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 35px;
  }

  &__404 {
    color: var(--disabled_font_color);
    font-size: 218px;
    font-weight: $text_bold;
    left: -163px;
    letter-spacing: 10px;
    position: absolute;
    top: -65px;
    white-space: nowrap;
    width: 100%;

    span {
      visibility: hidden;
    }
  }
}
/* Animation*/

@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-35deg);
  }
}
@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(35deg);
  }
}

@keyframes eat {
  0% {
    box-shadow:
      100px 65px 0 0 var(--disabled_font_color),
      300px 65px 0 0 var(--disabled_font_color),
      500px 65px 0 0 var(--disabled_font_color);
  }

  100% {
    box-shadow:
      40px 65px 0 0 var(--disabled_font_color),
      100px 65px 0 0 var(--disabled_font_color),
      300px 65px 0 0 var(--disabled_font_color);
  }
}
