.datatable-container {
  border: var(--high_contrast_border_width) solid
    var(--high_contrast_border_color_subtle);
  border-radius: $radius_big;
  box-shadow: var(--elevation_shadow_1);
  overflow-x: auto;
  width: 100%;

  &--radius-normal {
    border-radius: $radius_normal;

    .table {
      border-radius: $radius_normal;
    }
  }

  &--radius-small {
    border-radius: $radius_small;

    .table {
      border-radius: $radius_small;
    }
  }

  .table {
    width: 100%;
  }
}
