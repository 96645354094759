/**************************
Colors
***************************/
@import "colors/darktheme";
@import "colors/darkthemehc";
@import "colors/lighttheme";
@import "colors/lightthemehc";
@import "utils/utils";

// theme specific colors -  need to be css variables:
// light theme:
:root {
  --white_color_100: #{$l_white_color_100};
  --white_color_200: #{$l_white_color_200};
  --white_color_300: #{$l_white_color_300};

  --black_color_100: #{$l_black_color_100};
  --black_color_200: #{$l_black_color_200};
  --black_color_300: #{$l_black_color_300};

  // component/context related
  --app_background_gradient_color: linear-gradient(
    127deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 18%,
    rgba(204, 239, 252, 1) 42%,
    rgba(255, 255, 255, 1) 58%,
    rgba(225, 251, 255, 1) 82%,
    rgba(251, 255, 255, 1) 100%
  );

  --card_background_gradient_color: linear-gradient(
    127deg,
    rgb(255, 255, 255) 0%,
    rgb(246, 252, 252) 75%,
    rgb(240, 248, 248) 100%
  );
  --app_background_color: var(--white_color_200);
  --app_font_color: var(--black_color_100);
  --app_font_color_secondary: var(--black_color_300);
  --app_font_color_tertiary: var(--grey_color_darker);
  --disabled_background_color: var(--white_color_300);
  --disabled_font_color: var(--grey-alt_color);
  --high_contrast_border_color: transparent;
  --high_contrast_border_color_subtle: transparent;
  --high_contrast_border_width: 0;

  // Card
  --card_background_color: var(--white_color_100);
  --card_hover_color: var(--white_color_200);
  --card_background_semitransparent: var(--app_background_color);
  --card_background_alt_color: var(--white_color_200);
  --card_background_alt-2_color: var(--white_color_100);

  //Dialog
  --dialog_background_color: var(--white_color_200);

  //Input
  --input_background_color: var(--white_color_100);
  --input_border_color: transparent;

  //Table
  --table_solid_color: var(--white_color_100);
  --table_solid_color_alt: var(--white_color_200);
  --table_solid_color_hover: var(--white_color_300);

  //SlidingTabsBar
  --slide_tab_selected_color: var(--white_color_100);
  --slide_tab_background_color: var(--grey-alt_color_lighter);
  --slide_tab_active_icon_color: var(--csis_color);

  //Sidebar
  --sidebar_font_color: var(--csis_color);
  --sidebar_sun_icon_color: var(--app_font_color_tertiary);
  --sidebar_item_hover_color: var(--app_background_color);
  --sidebar_item_selected_color: var(--app_background_color);
  --sidebar_moon_icon_color: var(--app_font_color);

  // Shadows - Elevation
  --elevation_shadow_color: #ccc;
  --elevation_shadow_1: 0 0 5px var(--elevation_shadow_color);
  --elevation_shadow_2: 0 0 23px 0 rgba(0, 0, 0, 0.12);

  //Stepper
  --stepper_color: var(--grey_color_darker);

  // Skeleton
  --skeleton_background: var(--disabled_font_color);

  // CodeBlock
  --codeblock_color: var(--red_color_darker);
  --codeblock_bg_color: var(--red_color_transparent);
  --codeblock_grey_color: var(--app_font_color);
  --codeblock_grey_bg_color: var(--grey_color_transparent);

  //Accordion
  --accordion_solid_color: var(--white_color_200);
  --accordion_solid_color_hover: var(--csis_color_transparent);

  // Pill
  --pill_font_color: var(--csis_color_darker);

  // Tag
  --tag_font_color_red: var(--red_color_darker);
  --tag_font_color_csis: var(--csis_color_darker);
  --tag_font_color_orange: var(--orange_color_darker);
  --tag_font_color_yellow: var(--yellow_color_darker);

  //Dropdown
  --dropdown_list_background: var(--card_background_color);
  --dropdown_list_border: var(--card_background_color);
  --dropdown_background_color: var(--white_color_100);

  //Divider
  --divider_border: var(--disabled_font_color);

  //Focus
  --focus_color: var(--orange_color);

  //List
  --list_bg_even: var(--table_solid_color_alt);
  --list_bg_odd: var(--table_solid_color);

  //Heatcalendar
  --heatcalendar_default_square_fill_color: var(--csis_color);

  --heatcalendar_lvl1_square_fill_color: #00bdb775;
  --heatcalendar_lvl2_square_fill_color: #00aaa48f;
  --heatcalendar_lvl3_square_fill_color: #01a09bd5;
  --heatcalendar_lvl4_square_fill_color: #00817d;

  // MitreMatrix
  --mitre_lvl1_color: #1cdfd938;
  --mitre_lvl2_color: #06b6b09d;
  --mitre_lvl3_color: #007e7aec;
  --mitre_lvl4_color: #004746;

  @include createColor($l_csis_color, "csis_color");
  @include createColor($l_csisblue_color, "csis-blue_color");
  @include createColor($l_red_color, "red_color");
  @include createColor($l_blue_color, "blue_color");
  @include createColor($l_grey_color, "grey_color");
  @include createColor($l_greyalt_color, "grey-alt_color");
  @include createColor($l_orange_color, "orange_color");
  @include createColor($l_yellow_color, "yellow_color");
  @include createColor($l_green_color, "green_color");

  @include createColor($l_data1_color, "data1_color");
  @include createColor($l_data2_color, "data2_color");
  @include createColor($l_data3_color, "data3_color");
  @include createColor($l_data4_color, "data4_color");
  @include createColor($l_data5_color, "data5_color");
  @include createColor($l_data6_color, "data6_color");
  @include createColor($l_data7_color, "data7_color");
  @include createColor($l_data8_color, "data8_color");
  @include createColor($l_data9_color, "data9_color");
  @include createColor($l_data10_color, "data10_color");
}

:root[data-theme="dark"],
body[data-theme="dark"] {
  --white_color_100: #{$d_white_color_100};
  --white_color_200: #{$d_white_color_200};
  --white_color_300: #{$d_white_color_300};

  --black_color_100: #{$d_black_color_100};
  --black_color_200: #{$d_black_color_200};
  --black_color_250: #{$d_black_color_250};
  --black_color_300: #{$d_black_color_300};

  // component/context related
  --app_background_gradient_color: linear-gradient(
    127deg,
    rgba(1, 3, 5, 1) 0%,
    rgba(6, 23, 36, 1) 9%,
    rgba(11, 44, 68, 1) 21%,
    rgb(0, 85, 82) 38%,
    rgba(8, 24, 36, 1) 58%,
    rgba(0, 51, 84, 1) 82%,
    rgb(21, 90, 85) 100%
  );
  --card_background_gradient_color: linear-gradient(
    127deg,
    rgb(2, 66, 63) 0%,
    rgba(6, 23, 36, 1) 100%
  );
  --app_background_color: var(--black_color_100);
  --app_font_color: var(--white_color_100);
  --app_font_color_secondary: var(--white_color_100);
  --app_font_color_tertiary: var(--white_color_300);
  --disabled_background_color: var(--black_color_300);
  --disabled_font_color: var(--grey_color_darker);
  --high_contrast_border_color: transparent;
  --high_contrast_border_color_subtle: var(--grey_color_transparent);
  --high_contrast_border_width: 1px;

  //Card
  --card_background_color: var(--black_color_200);
  --card_hover_color: var(--black_color_300);
  --card_background_semitransparent: var(--grey_color_transparent);
  --card_background_alt_color: var(--app_background_color);
  --card_background_alt-2_color: var(--app_background_color);

  //Dialog
  --dialog_background_color: var(--card_background_color);

  //Input
  --input_background_color: var(--app_background_color);
  --input_border_color: transparent;

  //Table
  --table_solid_color: var(--black_color_100);
  --table_solid_color_alt: var(--black_color_250);
  --table_solid_color_hover: var(--black_color_300);

  //SlidingTabsBar
  --slide_tab_selected_color: var(--csis_color);
  --slide_tab_background_color: var(--black_color_100);
  --slide_tab_active_icon_color: var(--black_color_100);

  //Sidebar
  --sidebar_font_color: var(--white_color_200);
  --sidebar_item_hover_color: var(--grey_color_semi-transparent);
  --sidebar_item_selected_color: var(--grey_color_transparent);
  --sidebar_sun_icon_color: var(--app_font_color);
  --sidebar_moon_icon_color: var(--app_font_color);

  // Shadows - Elevation
  --elevation_shadow_color: rgba(0, 0, 0, 0.35);
  --elevation_shadow_1: 0 0 8px 0 var(--elevation_shadow_color);
  --elevation_shadow_2: 0 0 12px 2px var(--elevation_shadow_color);

  //Stepper
  --stepper_color: var(--grey_color);

  // CodeBlock
  --codeblock_color: var(--red_color_lighter);
  --codeblock_bg_color: var(--red_color_transparent);
  --codeblock_grey_color: var(--app_font_color);
  --codeblock_grey_bg_color: var(--black_color_300);

  // Skeleton
  --skeleton_background: var(--disabled_font_color);

  //Accordion
  --accordion_solid_color: var(--black_color_250);
  --accordion_solid_color_hover: var(--black_color_300);

  // Pill
  --pill_font_color: var(--csis_color_lighter);

  // Tag
  --tag_font_color_red: var(--red_color_lighter);
  --tag_font_color_csis: var(--csis_color_lighter);
  --tag_font_color_orange: var(--orange_color_lighter);

  //Dropdown
  --dropdown_list_background: var(--black_color_250);
  --dropdown_list_border: var(--black_color_250);
  --dropdown_background_color: var(--app_background_color);

  //Divider
  --divider_border: var(--disabled_font_color);

  //Focus
  --focus_color: var(--orange_color);

  //List
  --list_bg_even: var(--table_solid_color);
  --list_bg_odd: var(--table_solid_color_alt);

  // Heatcalendar
  --heatcalendar_default_square_fill_color: var(--csis_color);

  --heatcalendar_lvl1_square_fill_color: #4ca1a0;
  --heatcalendar_lvl2_square_fill_color: #35afab;
  --heatcalendar_lvl3_square_fill_color: #1cc4be;
  --heatcalendar_lvl4_square_fill_color: #07e7e0;

  // MitreMatrix
  --mitre_lvl1_color: #48c4c248;
  --mitre_lvl2_color: #28ece57a;
  --mitre_lvl3_color: #16c2bc;
  --mitre_lvl4_color: #00fff7;

  @include createColor($d_csis_color, "csis_color");
  @include createColor($d_csisblue_color, "csis-blue_color");
  @include createColor($d_red_color, "red_color");
  @include createColor($d_blue_color, "blue_color");
  @include createColor($d_grey_color, "grey_color");
  @include createColor($d_greyalt_color, "grey-alt_color");
  @include createColor($d_orange_color, "orange_color");
  @include createColor($d_yellow_color, "yellow_color");
  @include createColor($d_green_color, "green_color");

  @include createColor($d_data1_color, "data1_color");
  @include createColor($d_data2_color, "data2_color");
  @include createColor($d_data3_color, "data3_color");
  @include createColor($d_data4_color, "data4_color");
  @include createColor($d_data5_color, "data5_color");
  @include createColor($d_data6_color, "data6_color");
  @include createColor($d_data7_color, "data7_color");
  @include createColor($d_data8_color, "data8_color");
  @include createColor($d_data9_color, "data9_color");
  @include createColor($d_data10_color, "data10_color");
}

:root[data-highcontrast="true"],
body[data-highcontrast="true"] {
  --white_color_100: #{$hc_l_white_color_100};
  --white_color_200: #{$hc_l_white_color_200};
  --white_color_300: #{$hc_l_white_color_300};

  --black_color_100: #{$hc_l_black_color_100};
  --black_color_200: #{$hc_l_black_color_200};
  --black_color_300: #{$hc_l_black_color_300};

  // component/context related
  --app_background_gradient_color: var(--white_color_200);
  --card_background_gradient_color: var(--card_background_color);
  --app_background_color: var(--white_color_100);
  --app_font_color: var(--black_color_100);
  --app_font_color_secondary: var(--black_color_300);
  --app_font_color_tertiary: var(--black_color_300);
  --disabled_background_color: var(--white_color_300);
  --disabled_font_color: var(--grey-alt_color);
  --high_contrast_border_color: var(--csis_color);
  --high_contrast_border_color_subtle: var(--grey-alt_color);
  --high_contrast_border_width: 1px;

  // Card
  --card_background_color: var(--white_color_200);
  --card_hover_color: var(--white_color_200);
  --card_background_semitransparent: transparent;
  --card_background_alt_color: var(--app_background_color);
  --card_background_alt-2_color: var(--app_background_color);

  //Dialog
  --dialog_background_color: var(--card_background_color);

  //Input
  --input_background_color: var(--app_background_color);
  --input_border_color: var(--disabled_font_color);

  //Table
  --table_solid_color: var(--white_color_100);
  --table_solid_color_alt: var(--white_color_200);
  --table_solid_color_hover: var(--white_color_300);

  //SlidingTabsBar
  --slide_tab_selected_color: var(--white_color_100);
  --slide_tab_background_color: var(--grey-alt_color_lighter);
  --slide_tab_active_icon_color: var(--csis_color);

  //Sidebar
  --sidebar_font_color: var(--csis_color);
  --sidebar_sun_icon_color: var(--app_font_color);
  --sidebar_item_hover_color: var(--csis_color_transparent);
  --sidebar_item_selected_color: var(--csis_color_transparent);
  --sidebar_moon_icon_color: var(--app_font_color);

  // Shadows - Elevation
  --elevation_shadow_color: var(--grey-alt_color);
  --elevation_shadow_1: 0 0 6px 0 var(--elevation_shadow_color);
  --elevation_shadow_2: 0 0 23px 0 var(--elevation_shadow_color);

  //Stepper
  --stepper_color: var(--grey_color_darker);

  // Skeleton
  --skeleton_background: var(--disabled_font_color);

  // CodeBlock
  --codeblock_color: var(--red_color_darker);
  --codeblock_bg_color: var(--red_color_transparent);
  --codeblock_grey_color: var(--app_font_color);
  --codeblock_grey_bg_color: var(--white_color_300);

  //Accordion
  --accordion_solid_color: var(--white_color_200);
  --accordion_solid_color_hover: var(--csis_color_transparent);

  // Pill
  --pill_font_color: var(--csis_color_darker);

  // Tag
  --tag_font_color_red: var(--red_color_darker);
  --tag_font_color_csis: var(--csis_color_darker);
  --tag_font_color_orange: var(--orange_color_darker);

  //Dropdown
  --dropdown_list_background: var(--card_background_color);
  --dropdown_list_border: var(--card_background_color);
  --dropdown_background_color: var(--app_background_color);

  //Divider
  --divider_border: var(--disabled_font_color);

  //Focus
  --focus_color: var(--orange_color);

  //List
  --list_bg_even: var(--table_solid_color);
  --list_bg_odd: var(--table_solid_color_alt);

  //Heatcalendar
  --heatcalendar_default_square_fill_color: #9c0075;

  --heatcalendar_lvl1_square_fill_color: #c4009375;
  --heatcalendar_lvl2_square_fill_color: #e900afe3;
  --heatcalendar_lvl3_square_fill_color: #9c0075;
  --heatcalendar_lvl4_square_fill_color: #66004d;

  // MitreMatrix
  --mitre_lvl1_color: #1cdfd938;
  --mitre_lvl2_color: #06b6b09d;
  --mitre_lvl3_color: #007e7aec;
  --mitre_lvl4_color: #004746;

  @include createColor($hc_l_csis_color, "csis_color", true);
  @include createColor($hc_l_csisblue_color, "csis-blue_color", true);
  @include createColor($hc_l_red_color, "red_color", true);
  @include createColor($hc_l_blue_color, "blue_color", true);
  @include createColor($hc_l_grey_color, "grey_color", true);
  @include createColor($hc_l_greyalt_color, "grey-alt_color", true);
  @include createColor($hc_l_orange_color, "orange_color", true);
  @include createColor($hc_l_yellow_color, "yellow_color", true);
  @include createColor($hc_l_green_color, "green_color", true);

  @include createColor($hc_l_data1_color, "data1_color");
  @include createColor($hc_l_data2_color, "data2_color");
  @include createColor($hc_l_data3_color, "data3_color");
  @include createColor($hc_l_data4_color, "data4_color");
  @include createColor($hc_l_data5_color, "data5_color");
  @include createColor($hc_l_data6_color, "data6_color");
  @include createColor($hc_l_data7_color, "data7_color");
  @include createColor($hc_l_data8_color, "data8_color");
  @include createColor($hc_l_data9_color, "data9_color");
  @include createColor($hc_l_data10_color, "data10_color");
}

:root[data-theme="dark"][data-highcontrast="true"],
body[data-theme="dark"][data-highcontrast="true"] {
  --white_color_100: #{$hc_d_white_color_100};
  --white_color_200: #{$hc_d_white_color_200};
  --white_color_300: #{$hc_d_white_color_300};

  --black_color_100: #{$hc_d_black_color_100};
  --black_color_200: #{$hc_d_black_color_200};
  --black_color_250: #{$hc_d_black_color_250};
  --black_color_300: #{$hc_d_black_color_300};

  // component/context related
  --app_background_gradient_color: var(--black_color_100);
  --card_background_gradient_color: var(--card_background_color);
  --app_background_color: var(--black_color_200);
  --app_font_color: var(--white_color_100);
  --app_font_color_secondary: var(--white_color_100);
  --app_font_color_tertiary: var(--white_color_300);
  --disabled_background_color: var(--black_color_300);
  --disabled_font_color: var(--grey_color_darker);
  --high_contrast_border_color: var(--csis_color);
  --high_contrast_border_color_subtle: var(--grey_color_semi-transparent);
  --high_contrast_border_width: 1px;

  //Card
  --card_background_color: var(--black_color_100);
  --card_hover_color: var(--black_color_300);
  --card_background_semitransparent: transparent;
  --card_background_alt_color: var(--app_background_color);
  --card_background_alt-2_color: var(--black_color_100);

  //Dialog
  --dialog_background_color: var(--card_background_color);

  //Input
  --input_background_color: var(--app_background_color);
  --input_border_color: var(--disabled_font_color);

  //Table
  --table_solid_color: var(--black_color_250);
  --table_solid_color_alt: var(--black_color_100);
  --table_solid_color_hover: var(--black_color_300);

  //SlidingTabsBar
  --slide_tab_selected_color: var(--csis_color);
  --slide_tab_background_color: var(--black_color_100);
  --slide_tab_active_icon_color: var(--black_color_100);

  //Sidebar
  --sidebar_font_color: var(--white_color_200);
  --sidebar_item_hover_color: var(--csis_color_transparent);
  --sidebar_item_selected_color: var(--csis_color_transparent);
  --sidebar_sun_icon_color: var(--app_font_color);
  --sidebar_moon_icon_color: var(--app_font_color);

  // Shadows - Elevation
  --elevation_shadow_color: rgba(0, 0, 0, 0.16);
  --elevation_shadow_1: 0 0 8px 0 var(--elevation_shadow_color);
  --elevation_shadow_2: 0 0 12px 2px var(--elevation_shadow_color);

  //Stepper
  --stepper_color: var(--grey-alt_color);

  // CodeBlock
  --codeblock_color: var(--white_color_300);
  --codeblock_bg_color: var(--red_color_transparent);
  --codeblock_grey_color: var(--app_font_color);
  --codeblock_grey_bg_color: var(--black_color_300);

  // Skeleton
  --skeleton_background: var(--disabled_font_color);

  //Accordion
  --accordion_solid_color: var(--black_color_250);
  --accordion_solid_color_hover: var(--black_color_300);

  // Pill
  --pill_font_color: var(--csis_color_lighter);

  // Tag
  --tag_font_color_red: var(--red_color_lighter);
  --tag_font_color_csis: var(--csis_color_lighter);
  --tag_font_color_orange: var(--orange_color_lighter);

  //Dropdown
  --dropdown_list_background: var(--black_color_250);
  --dropdown_list_border: var(--black_color_250);
  --dropdown_background_color: var(--app_background_color);

  //Divider
  --divider_border: var(--disabled_font_color);

  //Focus
  --focus_color: var(--orange_color);

  //List
  --list_bg_even: var(--table_solid_color);
  --list_bg_odd: var(--table_solid_color_alt);

  // Heatcalendar
  --heatcalendar_default_square_fill_color: var(--csis_color);

  --heatcalendar_lvl1_square_fill_color: #4ca1a0;
  --heatcalendar_lvl2_square_fill_color: #35afab;
  --heatcalendar_lvl3_square_fill_color: #1cc4be;
  --heatcalendar_lvl4_square_fill_color: #07e7e0;

  // MitreMatrix
  --mitre_lvl1_color: #48c4c248;
  --mitre_lvl2_color: #28ece57a;
  --mitre_lvl3_color: #16c2bc;
  --mitre_lvl4_color: #00fff7;

  @include createColor($hc_d_csis_color, "csis_color");
  @include createColor($hc_d_csisblue_color, "csis-blue_color");
  @include createColor($hc_d_red_color, "red_color");
  @include createColor($hc_d_blue_color, "blue_color");
  @include createColor($hc_d_grey_color, "grey_color");
  @include createColor($hc_d_greyalt_color, "grey-alt_color");
  @include createColor($hc_d_orange_color, "orange_color");
  @include createColor($hc_d_yellow_color, "yellow_color");
  @include createColor($hc_d_green_color, "green_color");

  @include createColor($hc_d_data1_color, "data1_color");
  @include createColor($hc_d_data2_color, "data2_color");
  @include createColor($hc_d_data3_color, "data3_color");
  @include createColor($hc_d_data4_color, "data4_color");
  @include createColor($hc_d_data5_color, "data5_color");
  @include createColor($hc_d_data6_color, "data6_color");
  @include createColor($hc_d_data7_color, "data7_color");
  @include createColor($hc_d_data8_color, "data8_color");
  @include createColor($hc_d_data9_color, "data9_color");
  @include createColor($hc_d_data10_color, "data10_color");
}

// Media query stuff
// - width based
$mobile_screen_width: 768px;
$small_screen_width: 1200px;
$large_screen_width: 1550px;
$huge_screen_width: 1700px;
$ultra_large_screen_width: 2350px;
// - height based
$tall_screen_height: 1100px;

$screen_widths: (
  mobile: $mobile_screen_width,
  small: $small_screen_width,
  large: $large_screen_width,
  huge: $huge_screen_width,
);

:root {
  --multiplier: 1;
  --multiplier_2: 1;
  --multiplier_3: 1;
}

@media (max-width: $mobile_screen_width) {
  :root {
    --multiplier: 0.9;
    --multiplier_2: 0.85;
    --multiplier_3: 0.8;
  }
}

/**************************
Fonts
***************************/
// should aim to have max 6-7 font sizes.
// Browsers default font-size = 16px so 1em = 16px
$text_tiny: 11px;
$text_small: 14px;
$text_normal: calc(16px * var(--multiplier));
$text_big: calc(18px * var(--multiplier));
$text_large: calc(23px * var(--multiplier_2));
$text_huge: calc(32px * var(--multiplier_2));
$text_gigantic: calc(48px * var(--multiplier_3));
$text_gigantic_1: calc(64px * var(--multiplier_3));
$text_gigantic_2: calc(128px * var(--multiplier_3));

$text_light: 300;
$text_regular: 400;
$text_semibold: 500;
$text_bold: 600;
$text_black: 800;

// Sizes -USE THESE FOR margins/paddings etc
$spacing_tiny: 2px;
$spacing_small: calc(4px * var(--multiplier_2));
$spacing_normal: calc(8px * var(--multiplier_2));
$spacing_big: calc(16px * var(--multiplier_2));
$spacing_large: calc(24px * var(--multiplier_2));
$spacing_huge: calc(32px * var(--multiplier_2));
$spacing_gigantic: calc(48px * var(--multiplier_3));
$spacing_gigantic_1: calc(64px * var(--multiplier_3)); // use these very rarely
$spacing_gigantic_2: calc(128px * var(--multiplier_3)); // use these very rarely

$spacings: (
  tiny: $spacing_tiny,
  small: $spacing_small,
  normal: $spacing_normal,
  big: $spacing_big,
  large: $spacing_large,
  huge: $spacing_huge,
  gigantic: $spacing_gigantic,
);

// Border-widths
$border_width_normal: 2px;
$border_width_big: 4px;

// Radiuses
$radius_tiny: 3px;
$radius_small: 8px;
$radius_normal: 15px;
$radius_big: 18px;
$radius_gigantic: 50px;
$radius_circle: 50%;

// Z-indexes -- try to not use more than 6 values!
$z_index_behind: -1;
$z_index_above: 1;
$z_index_above_2: 2;
$z_index_above_3: 3;
$z_index_above_99: 99;
$z_index_above_100: 100;
$z_index_above_101: 101;
$z_index_above_102: 102;
$z_index_above_200: 200;

// Dialog sizes
$side_dialog_width: 42ch;

// Animations
$standard_transition: 0.25s ease;

// Opacity
$loading_opacity: 0.7;

// a defensive class to add to elements where we want them to prevent
// from growing too long and ruining the layout/responsivness
%limit-text-width {
  max-width: 29ch;
  overflow: hidden;
  text-overflow: ellipsis;
}
