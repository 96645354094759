.nodata {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &__mockchart {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    width: 200px;

    &__bar {
      background-color: var(--grey-alt_color);
      border-radius: $radius_tiny;
      height: 20px;

      width: $text_small;

      &:nth-child(1) {
        height: 90px;
      }

      &:nth-child(2) {
        height: 170px;
      }

      &:nth-child(3) {
        height: 130px;
      }

      &:nth-child(4) {
        height: 100px;
      }

      &:nth-child(5) {
        height: 40px;
      }
    }
  }

  &__title-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  &__title {
    background-color: var(--csis_color_transparent);
    border-radius: $radius_small;

    padding: $spacing_normal $spacing_big;
  }
}
