.timeline {
  &__line {
    border-left: 1px solid var(--csis_color);
    flex: 1;
  }

  &__title {
    color: var(--csis_color);
    font-size: $text_normal;
    font-weight: $text_semibold;
    line-height: $text_normal;
  }
}
