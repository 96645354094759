.main-app {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;

  &__container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 0;
    padding-left: $spacing_normal;
    padding-right: $spacing_big;
    padding-top: 0;
    position: relative;

    @media (max-width: $mobile_screen_width) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
