.tag {
  border-radius: $radius_tiny;
  box-sizing: border-box;
  display: inline-block;
  font-weight: $text_bold;
  line-height: $text_large;
  padding: $spacing_tiny $spacing_big;
  text-align: center;
  white-space: nowrap;

  > span {
    display: inline-block;
    min-width: 72px;
    white-space: nowrap;
  }

  &--csis {
    background-color: var(--csis_color_transparent);
    color: var(--tag_font_color_csis);
  }

  &--red {
    background-color: var(--red_color_transparent);
    color: var(--tag_font_color_red);
  }

  &--yellow {
    background-color: var(--yellow_color_transparent);
    color: var(--tag_font_color_yellow);
  }

  &--orange {
    background-color: var(--orange_color_transparent);
    color: var(--tag_font_color_orange);
  }

  &--blue {
    background-color: var(--blue_color_transparent);
    color: var(--blue_color);
  }

  &--green {
    background-color: var(--green_color_transparent);
    color: var(--green_color);
  }

  &--grey {
    background-color: var(--grey-alt_color_semi-transparent);
    color: var(--app_font_color);
  }

  &--elite {
    background-color: var(--app_font_color);
    color: var(--app_background_color);
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--circle {
    border-radius: $radius_big;
  }

  &--small {
    font-size: $text_small;
    line-height: $text_big;
    padding: $spacing_tiny $spacing_normal;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}
