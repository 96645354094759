$max_listmenu_height_possible: 300px;
$max_listmenu_width_possible: 420px;

.listmenu {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .card-base;
  border-radius: $radius_small;
  box-shadow: var(--elevation_shadow_2);
  display: inline-flex;
  flex-direction: column;
  margin-top: $spacing_small;
  overflow: hidden;
  position: absolute;
  z-index: $z_index_above_2;

  @media (max-width: $mobile_screen_width) {
    left: 10px;
    max-height: 70vh;
    min-width: 0;
    overflow: scroll;
    position: fixed;
    top: 70px;
    width: calc(100% - 20px);
    z-index: $z_index_above_102;
  }

  &__modal-container {
    position: absolute;
    z-index: $z_index_above_200;
  }

  &--row {
    flex-direction: row;
  }

  &__header {
    border-bottom: 1px solid var(--disabled_font_color);
    color: var(--app_font_color);
    font-weight: $text_semibold;
    margin-bottom: $spacing_small;
    padding: $spacing_normal;
    text-align: center;
    white-space: nowrap;
  }

  &__body {
    max-height: $max_listmenu_height_possible;
    overflow-y: auto;
    @media (max-width: $mobile_screen_width) {
      max-height: 250px;
    }
  }

  &__item {
    align-items: center;
    border-radius: $radius_tiny;
    color: var(--app_font_color);
    display: flex;

    margin: $spacing_small $spacing_normal;
    max-width: $max_listmenu_width_possible;
    padding: $spacing_normal $spacing_normal;
    white-space: nowrap;

    .icon {
      margin-right: $spacing_normal;
    }

    &:first-of-type {
      margin-top: $spacing_normal;
    }

    &:last-of-type {
      margin-bottom: $spacing_normal;
    }

    &--semibold {
      font-weight: $text_semibold;
    }

    &--disabled {
      color: var(--disabled_font_color);
    }
  }

  &--small {
    .listmenu__header {
      font-size: $text_small;
      padding: $spacing_small;
    }

    .listmenu__body {
      max-height: 230px;
    }

    .listmenu__item {
      font-size: $text_small;
      padding: $spacing_small $spacing_normal;
    }
  }

  &--big {
    .listmenu__item {
      padding: $spacing_normal $spacing_big;
    }
  }

  &__item--selectable {
    cursor: pointer;
  }

  &__item--selectable:hover {
    background-color: var(--grey-alt_color_semi-transparent);
  }

  &__item--idented {
    padding-left: $spacing_large;
  }

  &__item--selected {
    background-color: var(--grey-alt_color_semi-transparent);
    border: 1px solid var(--high_contrast_border_color);
  }

  &--opens-from-right {
    right: 0;
  }

  &--opens-from-top {
    bottom: $spacing_gigantic;
    @media (max-width: $mobile_screen_width) {
      bottom: unset !important;
    }

    &.listmenu--big-spacing {
      bottom: calc($spacing_gigantic + $spacing_big);
    }
  }

  &--has-minheight {
    .listmenu__body {
      min-height: $max_listmenu_height_possible;
    }
  }

  &--has-minwidth {
    .listmenu__body {
      max-width: $max_listmenu_width_possible;
      min-width: $max_listmenu_width_possible;
    }

    .checkbox__label {
      white-space: break-spaces;
    }
  }

  &--has-overflowvisible {
    overflow: visible;

    > .listmenu__body {
      overflow: visible;
    }
  }
}
