$tile_width: 275px;
$tile_height: 325px;

.reports-page {
  &__tile {
    /* stylelint-disable scss/at-extend-no-missing-placeholder */
    @extend .card-base;
    display: flex;
    flex-direction: column;
    max-width: $tile_width;
    overflow: hidden;

    &--loading {
      background-color: var(--grey_color_transparent);
      cursor: default;
    }

    &--hoverable {
      cursor: pointer;

      &:hover {
        box-shadow: var(--elevation_shadow_2);
      }
    }

    &__preview {
      background-color: var(--blue_color_transparent);
      box-sizing: border-box;
      height: $tile_height;
      padding: $spacing_small;
      width: $tile_width;
    }

    &__no-preview-available {
      align-items: center;
      background-color: var(--csis-blue_color);
      border-radius: $radius_big $radius_big 0 0;
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: $spacing_normal;
      position: relative;
      width: 100%;
    }

    &__preview-logo {
      height: auto !important;
      left: 15px;
      position: absolute;
      top: 15px;
      width: 40px;
    }

    &__preview-title {
      color: var(--white_color_100);
      font-size: $text_big;
      font-weight: $text_semibold;
      padding-right: $spacing_normal;
      text-align: right;
    }

    &__preview-timeframe {
      color: var(--white_color_100);
      font-size: $text_small;
      font-weight: $text_semibold;
      padding-right: $spacing_normal;
      text-align: right;
    }

    &__image {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      img {
        height: 100%;
      }

      &--horizontal {
        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .tag {
      position: absolute;
      right: $spacing_big;
      top: $spacing_big;
    }

    &__details {
      box-sizing: border-box;
      min-height: 85px;
      padding: $spacing_big;
    }

    &__title {
      font-weight: $text_semibold;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--new {
      cursor: pointer;

      .reports-page__tile__no-preview-available {
        align-items: center;
        background-color: var(--card_background_color);
        border: 1px dashed var(--csis_color);
      }
    }
  }
}
