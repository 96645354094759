.link {
  align-items: center;
  display: inline-flex;
  text-decoration: none;

  &--with-styling {
    color: var(--csis_color);
    font-weight: $text_semibold;

    .icon {
      background-color: var(--csis_color);
    }
  }

  .icon + span {
    margin-left: $spacing_normal;
  }

  span + .icon {
    margin-left: $spacing_normal;
  }

  &--disabled {
    color: var(--disabled_font_color);
    cursor: not-allowed;
    pointer-events: none;

    .icon {
      background-color: var(--disabled_font_color);
    }
  }

  &--small {
    font-size: $text_small;
    font-weight: $text_bold;
  }

  &--big {
    font-size: $text_big;
  }

  &--large {
    font-size: $text_large;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--fullwidth {
    width: 100%;
  }
}

.link--with-styling:hover {
  color: var(--csis_color_lighter);

  .icon {
    background-color: var(--csis_color_lighter);
  }
}
