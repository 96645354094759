.gauge {
  overflow: hidden;
  position: relative;

  &--size-small {
    height: 100px;
    width: 200px;
  }

  &--size-normal {
    height: 175px;
    width: 350px;
  }

  &--color-csis {
    background-color: var(--csis_color);
  }

  &--color-red {
    background-color: var(--red_color);
  }

  &--color-orange {
    background-color: var(--orange_color);
  }

  &__background {
    background-color: var(--grey-alt_color);
    border-radius: 250px 250px 0 0;
    height: 100%;
    position: absolute;
    top: 0%;
    width: 100%;
    z-index: 1;
  }

  &__negative {
    background-color: var(--app_background_color);
    border-radius: 250px 250px 0 0;
    height: 100%;
    left: 6%;
    position: absolute;
    top: 12.5%;
    width: 87.5%;
    z-index: 3;
  }

  &--background-card {
    .gauge__negative {
      background-color: var(--card_background_color);
    }
  }

  &__progress {
    border-radius: 0 0 250px 250px;
    height: 100%;
    position: absolute;
    top: 100%;
    transform-origin: center top;
    transition: transform 1.3s ease-in-out;
    width: 100%;
    z-index: 2;
  }

  &__data {
    height: 100%;
    letter-spacing: -1px;
    position: absolute;
    text-align: center;
    top: 45%;
    transition: all 1s ease-out;
    width: 100%;
    z-index: 5;
  }
}
