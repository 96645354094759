// inspired by tailwindcss we make use of some utility helper classes.
// at the moment they are limited to font-colors, sizes and weights
// we should avoid over using them and rely on BEM but sometimes they are helpful
// when we dont want to create a big ugly classname to style something small as a red text.

// example: <div className="f_large f_red f_semibold">i am a red title</div>

// Font sizes
.f_tiny {
  font-size: $text_tiny;
}

.f_small {
  font-size: $text_small;
}

.f_normal {
  font-size: $text_normal;
}

.f_big {
  font-size: $text_big;
}

.f_large {
  font-size: $text_large;
}

.f_huge {
  font-size: $text_huge;
}

.f_gigantic {
  font-size: $text_gigantic;
  line-height: $text_gigantic;
}

.f_gigantic_0 {
  font-size: $text_gigantic;
}

.f_gigantic_1 {
  font-size: $text_gigantic_1;
}

.f_gigantic_2 {
  font-size: $text_gigantic_2;
}

// Font weights
.f_light {
  font-weight: $text_light;
}

.f_regular {
  font-weight: $text_regular;
}

.f_semibold {
  font-weight: $text_semibold;
}

.f_bold {
  font-weight: $text_bold;
}

.f_black {
  font-weight: $text_black;
}

// Font styles
.f_italic {
  font-style: italic;
}

.f_underline {
  text-decoration: underline;
}

.f_underline-dashed {
  text-decoration: underline dashed 1px;
}

.f_underline-onhover {
  &:hover {
    text-decoration: underline;
  }
}

.f_capitalize {
  text-transform: capitalize;
}

.f_uppercase {
  text-transform: uppercase;
}

// Font colors
.f_csis {
  color: var(--csis_color);
}

.f_red {
  color: var(--red_color);
}

.f_orange {
  color: var(--orange_color);
}

.f_blue {
  color: var(--blue_color);
}

.f_red {
  color: var(--red_color);
}

.f_grey {
  color: var(--grey_color);
}

.f_grey-alt {
  color: var(--grey-alt_color);
}

.f_transparent {
  color: transparent;
}

.f_secondary {
  color: var(--app_font_color_secondary);
}

.f_tertiary {
  color: var(--app_font_color_tertiary);
}

.f_disabled {
  color: var(--disabled_font_color);
}

// Alignment
.f_center {
  text-align: center;
}

// Width
.width_100pct {
  width: 100%;
}

// Whitespace
.whitespace-break-spaces {
  white-space: break-spaces;
}

.whitespace-nowrap {
  white-space: nowrap;
}

// Cursor
.cursor_pointer {
  cursor: pointer;
}

// Onboarding
.onboarding-highlight {
  background-color: var(--csis_color_darker) !important;
  border-radius: 4px !important;
  color: var(--app_background_color) !important;

  div {
    color: var(--app_background_color) !important;
  }

  .icon {
    background-color: var(--app_background_color) !important;
  }
}

// Responsive related
@media (max-width: $mobile_screen_width) {
  .hide-on-mobile {
    display: none;
  }
}

.show-only-on-mobile {
  display: none;
  @media (max-width: $mobile_screen_width) {
    display: block;
  }
}

@media (max-width: $large_screen_width) {
  .hide-on-large-screen {
    display: none;
  }
}

.show-only-on-large-screen {
  display: none;
  @media (max-width: $large_screen_width) {
    display: block;
  }
}
