.checkbox {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  user-select: none;
  width: 100%;

  &--clickable-area-small {
    padding: $spacing_small;
  }

  &--clickable-area-normal {
    padding: $spacing_normal;
  }

  &--clickable-area-big {
    padding: $spacing_big;
  }

  &--clickable-area-large {
    padding: $spacing_large;
  }

  input {
    display: none;
  }

  &--disabled {
    color: var(--disabled_font_color);
  }
}

.checkbox--disabled {
  color: var(--disabled_font_color);
  cursor: default;
}

.checkbox__checkmark {
  align-items: center;
  border: $border_width_normal solid var(--csis_color);
  border-radius: $radius_tiny;
  box-sizing: border-box;
  display: inline-flex;
  height: $text_big;
  justify-content: center;
  min-width: $text_big;
  vertical-align: middle;
  width: $text_big;
}

.checkbox:hover .checkbox__checkmark {
  border: $border_width_normal solid var(--csis_color_lighter);
}

.checkbox:focus-visible {
  outline-width: 0;
}

.checkbox:focus-visible .checkbox__checkmark {
  box-shadow: 0 0 0 2px var(--focus_color);
  outline-width: 0;
}

.checkbox input:checked ~ .checkbox__checkmark {
  background-color: var(--csis_color);
  border: $border_width_normal solid var(--csis_color);
}

.checkbox--middle .checkbox__checkmark {
  background-color: var(--csis_color);
  border: $border_width_normal solid var(--csis_color);
}

.checkbox:hover input:checked ~ .checkbox__checkmark {
  background-color: var(--csis_color_lighter);
  border: $border_width_normal solid var(--csis_color_lighter);
}

.checkbox--middle:hover .checkbox__checkmark {
  background-color: var(--csis_color_lighter);
  border: $border_width_normal solid var(--csis_color_lighter);
}

.checkbox--disabled .checkbox__checkmark {
  border: $border_width_normal solid var(--disabled_font_color);
}

.checkbox--disabled:focus .checkbox__checkmark {
  box-shadow: none;
}

.checkbox--disabled:hover .checkbox__checkmark {
  border: $border_width_normal solid var(--disabled_font_color);
}

.checkbox--disabled input:checked ~ .checkbox__checkmark {
  background-color: var(--disabled_font_color);
  border: $border_width_normal solid var(--disabled_font_color);
}

.checkbox--disabled:hover input:checked ~ .checkbox__checkmark {
  background-color: var(--disabled_font_color);
  border: $border_width_normal solid var(--disabled_font_color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox__checkmark::after {
  content: "";
  display: none;
}

/* Create the minusmark/indicator (indicats middle state) */
.checkbox__minusmark {
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkbox__checkmark::after {
  display: flex;
}

/* Show the minusmark when middle state */
.checkbox--middle .checkbox__minusmark {
  display: flex;
}

.checkbox__checkmark::after {
  border: solid var(--app_background_color);
  border-width: 0 2px 2px 0;
  height: 8px;
  transform: rotate(45deg);
  width: 3px;
}

.checkbox__minusmark {
  background-color: var(--app_background_color);
  border: 0;
  height: 2px;
  width: 8px;
}

.checkbox__label {
  font-size: $text_normal;
  margin-left: $spacing_normal;
}
