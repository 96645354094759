$textarea_height: 120px;

.input {
  align-items: center;
  background-color: var(--input_background_color);
  border: 2px solid var(--input_border_color);
  border-radius: $radius_small;
  box-shadow: var(--elevation_shadow_1);
  box-sizing: border-box;
  color: var(--app_font_color);
  display: inline-flex;
  justify-content: center;
  position: relative;
  transition:
    background-color $standard_transition,
    color $standard_transition,
    border-color $standard_transition;

  .icon {
    background-color: var(--disabled_font_color);
    height: $text_large;
    margin-left: $spacing_big;
    transition: background-color $standard_transition;
    width: $text_large;
  }

  input,
  textarea {
    background-color: transparent;
    background-image: none;
    border: 0;
    box-shadow: none;
    color: var(--app_font_color);
    flex-grow: 1;
    font-size: $text_small;
    line-height: $text_small;
    padding: $spacing_big;
    @media (max-width: $mobile_screen_width) {
      font-size: 16px !important;
    }
  }

  input {
    height: $text_small;
  }

  textarea {
    resize: none;
  }

  &__textarea {
    height: $textarea_height;

    &--not-expaned {
      height: $text_small;
    }

    &--not-expaned:focus {
      height: $textarea_height;
    }
  }

  &:focus-within {
    border-color: var(--csis_color_darker);

    .icon {
      background-color: var(--csis_color_darker);
    }
  }

  &:hover {
    border-color: var(--csis_color);

    .icon {
      background-color: var(--csis_color);
    }
  }

  &--full-width {
    display: flex;
    width: 100%;
  }

  &--small {
    input,
    textarea {
      font-size: $text_small;
      padding: $spacing_normal;
      @media (max-width: $mobile_screen_width) {
        font-size: 16px !important;
      }
    }

    .icon {
      height: $text_big;
      margin-left: $spacing_normal;
      width: $text_big;
    }

    .input__optional {
      padding: $spacing_normal;
    }
  }

  &--large {
    input,
    textarea {
      font-size: $text_normal;
      padding: $spacing_large;
      @media (max-width: $mobile_screen_width) {
        font-size: 16px !important;
      }
    }

    input {
      height: $text_normal;
    }

    .icon {
      height: $text_large;
      margin-left: $spacing_large;
      width: $text_large;
    }
  }

  &--transparent {
    background-color: transparent;
  }

  &--semi-transparent {
    background-color: var(--sidebar_item_selected_color);
  }

  &--has-border {
    border-color: var(--high_contrast_border_color_subtle);
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--disabled {
    background-color: var(--disabled_background_color);
    border-color: var(--disabled_background_color);

    input {
      color: var(--disabled_font_color);
      font-weight: $text_semibold;
    }

    .icon {
      background-color: var(--disabled_font_color);
    }

    &:hover {
      background-color: var(--disabled_background_color);
      border-color: var(--disabled_background_color);

      .icon {
        background-color: var(--disabled_font_color);
      }
    }
  }

  &--readonly {
    border-color: transparent;

    &:hover {
      border-color: transparent;
    }
  }

  &--focus-solid {
    &:focus-within {
      background-color: var(--card_background_color);
    }
  }

  &--focus-transparent {
    &:focus-within {
      background-color: transparent;
    }
  }

  &--focus-shadow {
    &:focus-within {
      box-shadow: var(--elevation_shadow_1);
    }
  }

  &--focus-no-shadow {
    &:focus-within {
      box-shadow: none;
    }
  }

  &__optional {
    font-size: $text_tiny;
    padding: $spacing_big;
  }

  &__password-show {
    padding: $spacing_normal;

    .icon {
      cursor: pointer;
      margin: 0;
    }
  }

  &__actions {
    padding-right: $spacing_big;

    .icon {
      cursor: pointer;
      margin: 0;
    }
  }
}

.input:focus {
  outline: 0;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}
