.dark__theme__switch {
  box-sizing: border-box;

  &__container {
    position: relative;
  }

  &__item {
    &--sun {
      left: 5px;
      position: absolute;
      top: 5px;
      z-index: $z_index_above_100;

      .icon {
        background-color: var(--sidebar_sun_icon_color);
      }
    }

    &--moon {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: $z_index_above_100;

      .icon {
        background-color: var(--sidebar_moon_icon_color);
      }
    }
  }
}
