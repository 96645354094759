.indicator {
  box-sizing: border-box;
  color: var(--app_font_color);
  display: flex;
}

.dot {
  &--csis {
    .icon {
      background-color: var(--csis_color);
    }
  }

  &--red {
    .icon {
      background-color: var(--red_color);
    }
  }

  &--orange {
    .icon {
      background-color: var(--orange_color);
    }
  }

  &--blue {
    .icon {
      background-color: var(--blue_color);
    }
  }

  &--grey {
    .icon {
      background-color: var(--grey_color);
    }
  }
}
