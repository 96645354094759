.accordion {
  border-radius: $radius_big;
  box-shadow: var(--elevation_shadow_1);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;

  &__item {
    border: var(--high_contrast_border_width) solid
      var(--high_contrast_border_color_subtle);

    display: inline-flex;
    flex-direction: column;
    margin-bottom: 0;

    &__header {
      background-color: var(--accordion_solid_color);
      border: 0;
      color: var(--csis_color);
      cursor: pointer;
      font-weight: $text_semibold;
      margin-bottom: 0;
      padding: 0;

      &:hover {
        background-color: var(--accordion_solid_color_hover);
      }

      &__button {
        background: transparent;
        border: 0;
        border-radius: $radius_small;
        color: var(--csis_color);
        cursor: pointer;
        font-weight: $text_semibold;
        padding: $spacing_big;
        width: 100%;

        &--has-sidecontent {
          padding-right: 0;
        }

        &--grey {
          color: var(--app_font_color_secondary);
        }
      }
    }

    &__body {
      background: var(--card_background_color);
      padding: $spacing_big;
    }

    .accordion__item__header--transparent {
      background-color: transparent;
      padding: 0;
    }

    .accordion__item__header__button--transparent {
      padding-left: 0;
      padding-right: 0;
    }

    .accordion__item__body--transparent {
      background: transparent;
      padding: 0;
    }
  }

  &--full-width {
    display: flex;
    width: 100%;
  }

  &--transparent {
    box-shadow: none;
    overflow: visible;

    .accordion__item {
      border: 0;
    }
  }

  &--big {
    .accordion__item__header {
      font-size: $text_big;
      padding: $spacing_big;
    }

    .accordion__item__body {
      padding: $spacing_big;
    }

    &.accordion--transparent {
      .accordion__item__header {
        margin-bottom: $spacing_big;
        padding: 0;
      }

      .accordion__item__body {
        padding: 0;
      }
    }
  }
}
