.phishing-url-page {
  &__new-entry-button {
    bottom: $spacing_gigantic_1;
    position: fixed;
    right: $spacing_gigantic_1;
  }

  &__capture-container {
    border: 2px solid var(--csis_color);
    height: 400px;
    max-width: 400px;
    min-width: 250px;
    overflow: hidden;
    width: 100%;
  }

  &__thumbnail-container {
    height: $spacing_gigantic_1;
    overflow: hidden;
    width: $spacing_gigantic_1;
  }

  &__network-country {
    position: relative;

    &::before {
      border: 2px dotted var(--disabled_font_color);

      border-right: 0;
      border-top: 0;
      content: "";
      height: 11px;
      left: -13px;
      position: absolute;
      width: 10px;
    }
  }

  &__network-ip {
    position: relative;

    &::before {
      border: 2px dotted var(--disabled_font_color);

      border-right: 0;
      border-top: 0;
      content: "";
      height: 17px;
      left: -13px;
      position: absolute;
      top: -8px;
      width: 10px;
    }
  }

  &__html-container {
    max-height: 500px;
    overflow: auto;
  }
}
