/* prettier-ignore */
$svgs: "account",
  "add",
  "alert_attached",
  "alert_circle",
  "alert",
  "android_logo",
  "asn",
  "attachment",
  "autoplay",
  "awaiting_csis_reply",
  "awaiting_your_reply",
  "bell",
  "bitcoin",
  "block",
  "category",
  "check",
  "close",
  "closed",
  "cloud_off",
  "cloud",
  "comments",
  "confirmed",
  "copy",
  "create_ticket",
  "cve",
  "dark_mode",
  "dashboard",
  "delete",
  "detail_view",
  "dns",
  "domain",
  "dot",
  "download",
  "down-arrow",
  "down-arrow-drop",
  "down-arrow-with-line",
  "drag",
  "edit",
  "focus",
  "fullscreen_exit",
  "fullscreen",
  "global_search",
  "globe",
  "grid_view",
  "hash",
  "help_filled",
  "hub",
  "info",
  "in_progress",
  "ip",
  "left-arrow",
  "light_mode",
  "list_view",
  "linux_logo",
  "lock_reset",
  "lock",
  "logout",
  "malware",
  "menu_collapsed",
  "menu_expanded",
  "more",
  "new",
  "no_lock",
  "no_wifi",
  "north",
  "open_in_new",
  "organization",
  "paused",
  "pdf",
  "phone",
  "print",
  "questionmark",
  "refresh",
  "reports",
  "resolved",
  "right-arrow",
  "send",
  "settings",
  "shield",
  "sort",
  "source",
  "south",
  "star",
  "stats",
  "threat_actor",
  "star_outline",
  "threat_insights",
  "ticket_attached",
  "ticket_author",
  "tickets",
  "unfold_less",
  "unfold_more",
  "up-arrow",
  "up-arrow-drop",
  "up-and-down-arrow-drop",
  "up-arrow-with-line",
  "viewlist",
  "visibility_off",
  "visibility",
  "warning",
  "windows_logo",
  "wifi",
  "world",
  "x509",
  "zoom_in",
  "zoom_out",
  "zoom_reset";

@each $svg in $svgs {
  .icon--#{$svg} {
    mask: url(svgs/#{$svg}.svg);
  }
}
