.chronos-card {
  &__chart {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      height: 250px; // recharts need a container to define width and height
      width: 80%; // recharts need a container to define width and height
    }
  }

  &__carousel {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .carousel__slides {
      height: 250px; // recharts need a container to define width and height
      width: 80%; // recharts need a container to define width and height
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    width: 100%;

    .table {
      width: 100%;
    }
  }
}
