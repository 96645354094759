.radiobutton {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: $text_normal;
  position: relative;
  user-select: none;

  input {
    display: none;
  }
}

.radiobutton__checkmark {
  align-items: center;
  border: $border_width_normal solid var(--csis_color);
  border-radius: $radius_circle;
  display: inline-flex;
  height: $text_normal;
  justify-content: center;
  margin-right: $spacing_normal;
  width: $text_normal;
}

.radiobutton:focus-visible {
  outline-width: 0;
}

.radiobutton:hover .radiobutton__checkmark {
  border: $border_width_normal solid var(--csis_color_lighter);
}

.radiobutton:focus-visible .radiobutton__checkmark {
  box-shadow: 0 0 0 2px var(--focus_color);
}

.radiobutton__checkmark::after {
  content: "";
  display: none;
}

/* Show the checkmark when checked */
.radiobutton input:checked ~ .radiobutton__checkmark::after {
  display: block;
}

.radiobutton__checkmark::after {
  background-color: var(--csis_color);
  border-radius: 60%;
  height: 10px;
  position: relative;
  width: 10px;
}
