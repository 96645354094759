.sliding-tabs-bar {
  background-color: var(--slide_tab_background_color);
  border-radius: $radius_small;
  display: flex;
  position: relative;
  width: fit-content;

  &__tab-underline {
    background-color: var(--slide_tab_selected_color);
    border: 2px solid var(--slide_tab_background_color);
    border-radius: $radius_small;
    bottom: 0;
    box-sizing: border-box;
    height: 100%;
    position: absolute;
    transition:
      left 0.3s ease,
      width 0.3s ease,
      background-color 0.3s ease;
  }

  &__tab-button {
    background: unset;
    border: unset;
    padding: $spacing_small $spacing_normal;
    text-align: center;
    transition: color 0.3s ease;

    div {
      color: var(--app_font_color_secondary);
    }

    &--active {
      div {
        color: var(--slide_tab_active_icon_color);
        z-index: 3;
      }

      .icon {
        background-color: var(--slide_tab_active_icon_color);
      }
    }
  }
}
