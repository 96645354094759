.searchable-cell {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;

  &__icon {
    display: block;
    margin-left: $spacing_small;
    visibility: hidden;
  }

  &:hover {
    color: var(--csis_color);
    text-decoration: underline;

    .icon {
      background-color: var(--csis_color);
      visibility: visible;
    }
  }

  &--small {
    font-size: $text_small;
  }

  &--red {
    color: var(--red_color);

    &:hover {
      color: var(--red_color);

      .icon {
        background-color: var(--red_color);
      }
    }
  }

  &--blue {
    color: var(--blue_color);

    &:hover {
      color: var(--blue_color);

      .icon {
        background-color: var(--blue_color);
      }
    }
  }
}
