.headertabs {
  align-self: stretch;
  color: var(--csis_color);
  display: flex;
  font-weight: $text_semibold;

  @media (max-width: $mobile_screen_width) {
    display: none;
  }

  &__item {
    border: $border_width_normal solid transparent;
    border-radius: 2px;
    cursor: pointer;
    margin-right: $spacing_big;

    &:last-child {
      margin-right: 0;
    }

    &--big {
      font-size: $text_big;
    }

    &--large {
      font-size: $text_large;
    }

    > span {
      color: var(--csis_color);
    }

    &.headertabs__item--selected {
      border-bottom: $border_width_normal solid var(--csis_color);
      color: var(--csis_color);
    }

    &:hover {
      color: var(--csis_color_lighter);

      > span {
        color: var(--csis_color_lighter);
      }
    }

    &.headertabs__item--disabled {
      color: var(--disabled_font_color);

      &:hover {
        border-color: transparent;
        cursor: default;
      }
    }
  }
}
