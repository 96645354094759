.tabs {
  width: 100%;

  &__header {
    border-bottom: 1px solid var(--disabled_font_color);
    width: 100%;
  }

  &__tab {
    @extend %limit-text-width;
    color: var(--app_font_color_secondary);
    cursor: pointer;
    font-weight: $text_semibold;
    padding-bottom: $spacing_small;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
      border-bottom: 2px solid var(--csis_color);
    }

    &:focus {
      border-bottom: 2px solid var(--csis_color);
    }

    &--selected {
      border-bottom: 2px solid var(--csis_color);
    }
  }
}
