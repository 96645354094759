.stack {
  box-sizing: border-box;
  display: flex;

  &--gutter-none {
    > * {
      margin: 0;
    }
  }

  &--gutter-tiny {
    > * {
      margin: $spacing_tiny;
    }
  }

  &--gutter-small {
    > * {
      margin: $spacing_small;
    }
  }

  &--gutter-normal {
    > * {
      margin: $spacing_normal;
    }
  }

  &--gutter-big {
    > * {
      margin: $spacing_big;
    }
  }

  &--gutter-large {
    > * {
      margin: $spacing_large;
    }
  }

  &--gutter-huge {
    > * {
      margin: $spacing_huge;
    }
  }

  &--gutter-gigantic {
    > * {
      margin: $spacing_gigantic;
    }
  }

  &--gutter-gigantic1 {
    > * + * {
      margin-left: $spacing_gigantic_1;
      margin-top: $spacing_gigantic_1;
    }
  }

  &--gutter-gigantic2 {
    > * + * {
      margin-left: $spacing_gigantic_2;
      margin-top: $spacing_gigantic_2;
    }
  }

  &--horizontal {
    align-items: flex-start;
    flex-direction: row;

    > *:first-child {
      // first element 0 margin
      margin: 0;
    }

    > * + * {
      // rest keep only margin-left
      margin-bottom: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }

  &--horizontal-reverse {
    align-items: flex-start;
    flex-direction: row-reverse;

    > *:first-child {
      // first element 0 margin
      margin: 0;
    }

    > * + * {
      // rest keep only margin-right
      margin-bottom: 0;
      margin-left: 0;
      margin-top: 0;
    }
  }

  &--vertical {
    align-items: flex-start;
    flex-direction: column;

    > *:first-child {
      // first element 0 margin
      margin: 0;
    }

    > * + * {
      // keep only margin-top
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--vertical-reverse {
    align-items: flex-start;
    flex-direction: column-reverse;

    > *:first-child {
      // first element 0 margin
      margin: 0;
    }

    > * + * {
      // keep only margin-bottom
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }

  &--wrap {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      margin-left: 0;
      margin-top: 0;
    }
  }

  &--align-center {
    align-items: center;
  }

  &--align-stretch {
    align-items: stretch;

    &.stack--vertical {
      width: 100%;
    }
  }

  &--align-end {
    align-items: flex-end;
  }

  &--justify-space-between {
    justify-content: space-between;
  }

  &--justify-space-evenly {
    justify-content: space-evenly;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-right {
    justify-content: flex-end;
  }
}

.stack--horizontal.stack--expanded {
  flex-grow: 1;
  width: 100%;
}

.stack--vertical.stack--expanded {
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.stack--wrap.stack--expanded {
  flex-grow: 1;
  width: 100%;
}
