$all_colors: (
  "csis": var(--csis_color),
  "red": var(--red_color),
  "blue": var(--blue_color),
  "orange": var(--orange_color),
  "green": var(--green_color),
  "yellow": var(--yellow_color),
  "data1": var(--data1_color),
  "data2": var(--data2_color),
  "data3": var(--data3_color),
  "data4": var(--data4_color),
  "data5": var(--data5_color),
  "data6": var(--data6_color),
  "data7": var(--data7_color),
  "data8": var(--data8_color),
  "data9": var(--data9_color),
  "data10": var(--data10_color),
);

// Texts and lines
.recharts-cartesian-grid-horizontal {
  line {
    stroke: var(--grey-alt_color);
  }
}

.recharts-cartesian-axis-tick-line {
  stroke: var(--grey-alt_color);
}

.recharts-text {
  fill: var(--app_font_color_secondary);
}

// line
.csis-line-chart {
  .recharts-line-curve {
    stroke: var(--csis_color);
  }

  .recharts-line-dot {
    fill: var(--csis_color);
    stroke: var(--csis_color);
  }

  @each $key, $color in $all_colors {
    .recharts-line.#{$key} {
      .recharts-line-curve {
        stroke: $color !important;
      }

      .recharts-line-dot {
        fill: $color;
        stroke: $color !important;
      }
    }
  }
}

// area
.csis-area-chart {
  .recharts-area-curve {
    stroke: var(--csis_color);
  }

  .recharts-area-area {
    fill: var(--csis_color_transparent);
  }
}

// pie
.csis-pie-chart {
  @each $key, $color in $all_colors {
    .recharts-sector.#{$key} {
      fill: $color;
      stroke: $color;
    }
  }

  &--clickable {
    .recharts-pie-sector {
      cursor: pointer;
    }
  }

  &__center-key {
    fill: var(--app_font_color);
  }

  &__center-value {
    fill: var(--app_font_color);
    font-size: $text_large;
    font-weight: $text_semibold;
  }

  &__legend {
    color: var(--app_font_color);
    font-size: $text_small;
    font-weight: $text_semibold;
    text-transform: uppercase;

    &__icon {
      background-color: var(--app_font_color);
      border-radius: $radius_circle;
      display: inline-block;
      height: 9px;
      margin-right: $spacing_normal;
      width: 9px;

      @each $key, $color in $all_colors {
        &--#{$key} {
          background-color: $color;
        }
      }
    }
  }
}

// bar
.csis-bar-chart {
  .recharts-rectangle {
    fill: var(--csis_color);
  }

  &--clickable {
    .recharts-bar {
      cursor: pointer;
    }

    .recharts-surface {
      cursor: pointer;
    }
  }

  @each $key, $color in $all_colors {
    .recharts-bar.#{$key} {
      .recharts-rectangle {
        fill: $color;
      }
    }
  }

  .recharts-rectangle.recharts-tooltip-cursor {
    fill: var(--disabled_background_color);
  }

  // Tooltip
  &__tooltip {
    background: var(--card_background_color);
    background-color: var(--card_background_color);
    border-radius: $radius_normal;
    box-sizing: border-box;
    padding: $spacing_big;
    // do not use shadow here, it cripples performance

    &__title {
      font-size: $text_tiny;
      font-weight: $text_semibold;
    }

    &__label {
      color: var(--csis_color);
      font-size: $text_tiny;

      @each $key, $color in $all_colors {
        &--#{$key} {
          color: $color;
        }
      }
    }
  }
}

// TableBarChart
.tablebarchart {
  &__value-container {
    background-color: var(--csis_color_transparent);
    border-radius: $radius_tiny;
    padding: $spacing_normal 0;

    > div {
      margin-left: $radius_tiny;
    }
  }
}
