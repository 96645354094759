.comment {
  background: var(--card_background_color);
  border: var(--high_contrast_border_width) solid
    var(--high_contrast_border_color_subtle);
  border-radius: $radius_normal;
  box-shadow: var(--elevation_shadow_1);
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: $spacing_big;
  position: relative;

  &__badge {
    align-self: flex-end;
    height: $text_normal;
  }

  &__header {
    display: block;
    @media (max-width: $mobile_screen_width) {
      display: none;
    }
  }

  &__header--mobile {
    display: none;
    @media (max-width: $mobile_screen_width) {
      display: block;
    }
  }

  &__avatar {
    align-items: center;
    border-radius: $radius_circle;
    box-sizing: border-box;
    cursor: default;
    display: flex;
    font-size: $text_small;
    font-weight: $text_semibold;
    height: $spacing_huge;
    justify-content: center;
    min-height: $spacing_huge;
    min-width: $spacing_huge;
    text-transform: uppercase;
    width: $spacing_huge;
  }

  &__body {
    padding-left: $spacing_normal;
    padding-right: $spacing_normal;
    padding-top: $spacing_normal;
    white-space: break-spaces;
    word-wrap: anywhere;
  }

  @media (max-width: $mobile_screen_width) {
    font-size: $text_small;
  }
}
