.onboardinghelp {
  &__section {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
    text-align: justify;
  }
}
