.passwordreset-page {
  align-items: flex-start;
  background-color: var(--csis_color_darker);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  padding: $spacing_gigantic_1 $spacing_gigantic_2;

  &__logo {
    width: 120px;

    img {
      width: 100%;
    }
  }

  &__title {
    color: var(--white_color_100);
  }

  &__form {
    width: 440px;
  }

  &__login-redirect {
    font-weight: $text_semibold;

    &:hover {
      text-decoration: underline;
    }
  }

  &__footer {
    align-items: center;
    color: var(--white_color_100);
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: $spacing_large;

    &__link {
      color: var(--white_color_100);
      font-size: $text_small;
      font-weight: $text_semibold;
      text-transform: uppercase;
    }

    &__dot {
      background-color: #fff;
      height: 2px;
      width: 2px;
    }
  }
}
