$min_btn_width: 100px;

.button {
  align-items: center;
  border: $border_width_normal solid;
  border-radius: $radius_small;
  cursor: pointer;
  display: inline-flex;
  font-size: $text_small;
  font-weight: $text_bold;
  justify-content: center;
  line-height: $text_small;
  padding: $spacing_big;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;

  span {
    font-size: $text_small;
    line-height: $text_small;
  }

  &--full-width {
    width: 100%;
  }

  &--small {
    padding: $spacing_normal;
  }

  &__spinner-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.button--primary {
  background-color: var(--csis_color);
  border-color: var(--csis_color);
  color: var(--app_background_color);
  min-width: $min_btn_width;

  &:hover {
    background-color: var(--csis_color_lighter);
    border-color: var(--csis_color_lighter);
    color: var(--app_background_color);
  }

  &:active {
    background-color: var(--csis_color_darker);
    border-color: var(--csis_color_darker);
  }

  .icon {
    background-color: var(--app_background_color);
  }

  &.button--blue {
    background-color: var(--blue_color);
    border-color: var(--blue_color);

    &:hover {
      background-color: var(--blue_color_lighter);
      border-color: var(--blue_color_lighter);
    }

    &:active {
      background-color: var(--blue_color_darker);
      border-color: var(--blue_color_darker);
    }

    .icon {
      background-color: var(--app_background_color);
    }
  }

  &.button--red {
    background-color: var(--red_color);
    border-color: var(--red_color);

    &:hover {
      background-color: var(--red_color_lighter);
      border-color: var(--red_color_lighter);
    }

    &:active {
      background-color: var(--red_color_darker);
      border-color: var(--red_color_darker);
    }

    .icon {
      background-color: var(--app_background_color);
    }
  }

  &.button--grey {
    background-color: var(--grey-alt_color);
    border-color: var(--grey-alt_color);
    color: var(--app_font_color);

    &:hover {
      background-color: var(--grey-alt_color_lighter);
      border-color: var(--grey-alt_color_lighter);
    }

    &:active {
      background-color: var(--grey-alt_color_darker);
      border-color: var(--grey-alt_color_darker);
    }

    .icon {
      background-color: var(--app_font_color);
    }

    .spinner {
      background-color: var(--grey-alt_color_darker);
    }
  }

  &.button--disabled {
    background-color: var(--disabled_background_color);
    border-color: var(--disabled_background_color);
    color: var(--disabled_font_color);
    cursor: not-allowed;
    pointer-events: none;

    .icon {
      background-color: var(--disabled_font_color);
    }
  }

  &.button--loading {
    cursor: not-allowed;
    opacity: $loading_opacity;
    pointer-events: none;
  }
}

.button--text {
  background-color: transparent;
  border-color: transparent;
  color: var(--csis_color);
  padding: $spacing_small 0;

  .icon {
    background-color: var(--csis_color);
  }

  &.button--loading {
    cursor: not-allowed;
    pointer-events: none;

    .spinner {
      background-color: var(--csis_color);
    }
  }

  &:hover {
    color: var(--csis_color_lighter);

    .icon {
      background-color: var(--csis_color_lighter);
    }
  }

  &.button--blue {
    color: var(--blue_color);

    .icon {
      background-color: var(--blue_color);
    }

    .spinner {
      background-color: var(--blue_color);
    }

    &:hover {
      color: var(--blue_color_lighter);

      .icon {
        background-color: var(--blue_color_lighter);
      }
    }
  }

  &.button--red {
    color: var(--red_color);

    .icon {
      background-color: var(--red_color);
    }

    .spinner {
      background-color: var(--red_color);
    }

    &:hover {
      color: var(--red_color_lighter);

      .icon {
        background-color: var(--red_color_lighter);
      }
    }

    .icon {
      background-color: var(--red_color);
    }
  }

  &.button--grey {
    color: var(--grey_color);

    .icon {
      background-color: var(--grey_color);
    }

    .spinner {
      background-color: var(--disabled_font_color);
    }

    &:hover {
      color: var(--app_font_color_tertiary);

      .icon {
        background-color: var(--app_font_color_tertiary);
      }
    }
  }

  &.button--disabled {
    background-color: transparent;
    color: var(--disabled_font_color);
    cursor: not-allowed;
    pointer-events: none;

    .icon {
      background-color: var(--disabled_font_color);
    }
  }
}
