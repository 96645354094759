.emergency-case-confirmation-page {
  align-items: flex-start;
  background-color: var(--csis_color_darker);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  padding: $spacing_gigantic_1 $spacing_gigantic_2;

  @media (max-width: $mobile_screen_width) {
    padding: $spacing_gigantic;
  }

  &__view {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: $mobile_screen_width;
    width: 100%;
  }

  &__logo {
    width: 120px;

    img {
      width: 100%;
    }
  }

  &__confirmation {
    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 800px;
    }

    &__title {
      color: var(--black_color_100);
      font-size: $text-big;
      font-weight: $text_semibold;
      margin-bottom: $spacing_normal;
    }

    &__text {
      color: var(--black_color_100);
      font-size: $text-normal;
      font-weight: $text_regular;
      margin-bottom: $spacing_normal;
    }

    &__label {
      color: var(--black_color_100);
      font-size: $text-big;
      font-weight: $text_semibold;
      margin-right: $spacing_large;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__questionary {
    #emergency-case-questionary {
      width: 100%;
    }
  }

  &__timer {
    &__proggress-bar-wrapper {
      width: 100%;

      .progress-bar {
        background-color: var(--csis_color);
        border: 1px solid var(--white_color_100);

        &__progress {
          background-color: var(--white_color_100);
        }
      }
    }

    &__countdown-wrapper {
      font-size: $text-large;
      font-weight: $text_bold;
    }
  }
}
