$sidebar_minwidth: 320px;
$sidebar_minimized_minwidth: 112px;

.sidebar {
  background: transparent;
  box-sizing: border-box;
  color: var(--sidebar_font_color);
  display: inline-flex;
  flex-direction: column;
  max-width: 300px;
  min-width: $sidebar_minwidth;
  overflow-x: hidden;
  overflow-y: visible;
  padding: $spacing_big;
  position: relative;

  a {
    // needed for sidebar links become visible in chrome when tabbed
    display: block;
  }

  &__header {
    padding: $spacing_small 0;
    padding-left: calc($spacing_big + $spacing_tiny);
    @media screen and (max-width: $large_screen_width) {
      padding: $spacing_big 0;
    }

    .icon {
      background-color: var(--sidebar_font_color);
    }

    &__logo {
      color: transparent;
      display: flex;
      width: 100px;
      @media (max-width: $mobile_screen_width) {
        width: 75px;
      }
    }

    &__admin-indicator {
      color: var(--orange_color);
      font-size: $text_small;
      font-weight: $text_semibold;
    }
    @media (max-width: $mobile_screen_width) {
      padding-left: $spacing_huge;
      padding-right: $spacing_large;
    }
  }

  &__menu {
    flex-grow: 1;
    margin-top: $spacing_huge;
    @media (max-width: $mobile_screen_width) {
      margin-top: $spacing_large;
    }

    .icon {
      background-color: var(--sidebar_font_color);
    }

    .menuitem {
      align-items: center;
      border: 1px solid transparent;
      border-left: 3px solid transparent;
      border-radius: 3px 0 0 3px;
      cursor: pointer;
      display: flex;
      font-size: $text_normal;
      font-weight: $text_semibold;
      justify-content: flex-start;
      margin-bottom: $spacing_big;
      padding: $spacing_normal $spacing_big;

      &:hover {
        background-color: var(--sidebar_item_hover_color);
        border-bottom-right-radius: $radius_small;
        border-top-right-radius: $radius_small;
      }

      &--selected {
        background-color: var(--sidebar_item_selected_color);
        border: 1px solid var(--high_contrast_border_color);
        border-bottom-right-radius: $radius_small;
        border-left: 3px solid var(--csis_color);
        border-top-right-radius: $radius_small;
      }

      &__icon {
        margin-right: $spacing_big;
      }

      &__label {
        @extend %limit-text-width;
        text-transform: uppercase;
      }

      &__beta-indicator {
        color: var(--orange_color);
        font-size: $text_tiny;
        margin-left: 6px;
        margin-top: -10px;
      }

      @media (max-width: $mobile_screen_width) {
        padding-left: $spacing_huge;
      }
    }

    .second-level {
      margin-bottom: $spacing_big;

      &__loader {
        margin: $spacing_normal $spacing_gigantic $spacing_big $spacing_gigantic;
      }

      &__menuitem {
        @extend %limit-text-width;
        cursor: pointer;
        display: flex;
        font-size: $text_normal;
        margin: $spacing_normal 0 $spacing_normal $spacing_gigantic;
        @media screen and (max-width: $large_screen_width) {
          margin: $spacing_normal 0 $spacing_normal $spacing_huge;
        }
        @media (max-width: $mobile_screen_width) {
          margin-left: $spacing_gigantic;
        }

        &__beta-indicator {
          color: var(--orange_color);
          font-size: $text_tiny;
          font-weight: 600;
          margin-left: 6px;
        }

        &--selected {
          font-weight: 600;
        }
      }
    }

    .third-level {
      margin-left: $spacing_large;

      &__menuitem {
        @extend %limit-text-width;
        cursor: pointer;
        font-size: $text_normal;
        margin: $spacing_normal 0 $spacing_normal $spacing_gigantic;
        @media screen and (max-width: $large_screen_width) {
          margin: $spacing_normal 0 $spacing_normal $spacing_huge;
        }

        &--selected {
          font-weight: 600;
        }
      }
    }

    .fourth-level {
      margin-left: $spacing_huge;

      &__menuitem {
        @extend %limit-text-width;
        cursor: pointer;
        font-size: $text_normal;
        margin: $spacing_normal 0 $spacing_normal $spacing_gigantic;
        @media screen and (max-width: $large_screen_width) {
          margin: $spacing_normal 0 $spacing_normal $spacing_huge;
        }

        &--selected {
          font-weight: 600;
        }
      }
    }
  }

  &__footer {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: row;
    left: 0;
    margin-top: $spacing_huge;
    padding: $spacing_big;
    @media (max-width: $mobile_screen_width) {
      padding-left: $spacing_huge;
    }
  }

  &--minimized {
    min-width: $sidebar_minimized_minwidth;

    .sidebar__header {
      padding-left: 0;
    }

    .sidebar__header > .stack {
      justify-content: center;
    }

    .sidebar__org-dropdown {
      display: none;
    }

    .sidebar__menu {
      .menuitem {
        justify-content: center;

        &__icon {
          margin-right: 0;
        }
      }

      .menuitem__label {
        display: none;
      }

      .second-level {
        display: none;
      }

      .third-level {
        display: none;
      }
    }
  }

  @media (max-width: $mobile_screen_width) {
    display: none;
  }
}

.sidebar-mobile {
  box-sizing: border-box;
  color: var(--sidebar_font_color);
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z_index_above_100;

  @media (max-width: $mobile_screen_width) {
    display: flex;
  }

  .sidebar__org-dropdown {
    padding: $spacing_big;
  }

  &__icon {
    align-items: center;
    background-color: var(--app_background_color);
    box-sizing: border-box;
    display: flex;
    height: 59px;
    justify-content: center;
    padding: $spacing_normal $spacing_large;
    padding-left: 0;
  }

  &__dialog {
    background-color: var(--card_background_color);
    height: 100dvh;
    left: 0;
    min-height: 100dvh;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $z_index_above_101;
  }
}
