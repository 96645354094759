.collapsable-panel {
  border-radius: $radius_big;
  box-shadow: var(--elevation_shadow_1);
  box-sizing: border-box;
  display: flex;

  &__sidebar {
    background-color: var(--csis_color_transparent);
    border-radius: $radius_big 0 0 $radius_big;
    box-sizing: border-box;
    flex-basis: 340px;
    flex-shrink: 0;
    padding: $spacing_big;

    &__slide-button {
      margin-bottom: $spacing_normal;
    }

    &__elements {
      padding: $spacing_normal;
    }

    &__group {
      &--sticky {
        height: 98vh;
        overflow-y: auto;
        position: sticky;
        top: 0;
      }
    }

    &--collapsed {
      flex-basis: 0;

      .collapsable-panel__sidebar__elements {
        display: none;
      }
    }
  }

  &__content {
    background-color: var(--card_background_color);
    border-radius: 0 $radius_big $radius_big 0;
    box-sizing: border-box;
    flex-grow: 1;
    overflow: auto;
    padding: $spacing_large;
  }
}
