.threat-insights-article {
  &__contents {
    box-sizing: border-box;
    overflow-wrap: anywhere;
    padding: $spacing_big;
    width: 100%;

    p {
      white-space: break-spaces;
    }

    a {
      color: var(--csis_color);
    }

    a:hover {
      text-decoration: underline;
    }

    pre,
    code {
      background: var(--codeblock_grey_bg_color);
      border-radius: $radius_small;
      box-sizing: border-box;
      color: var(--app_font_color_secondary);
      cursor: text;
      display: inline-block;
      font-family: "Courier New", Courier, monospace;
      margin: 0;
      padding: $spacing_small $spacing_normal;
      white-space: break-spaces;
      word-break: break-word;
    }
  }
}
