.rangeslider {
  -webkit-appearance: none;
  appearance: none;
  background: var(--grey-alt_color);
  border-radius: $radius_small;
  cursor: pointer;
  height: 4px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: var(--csis_color);
    border: 0;
    border-radius: $radius_circle;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  &::-moz-range-thumb {
    background: var(--csis_color);
    border: 0;
    border-radius: $radius_circle;
    box-shadow: var(--elevation_shadow_1);
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
}

input[type="range"]::-moz-range-progress {
  background-color: var(--csis_color);
  border-radius: $radius_small;
  height: 4px;
}
