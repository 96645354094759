// 1. give it an almost white background so transparent png images dont appear "ugly"

.image {
  background-color: #fafafa; // 1

  &__preview-mask {
    align-items: flex-start;
    background-color: var(--csis_color_transparent);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
    left: 0;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z_index_dialog;

    > img {
      background-color: #fafafa; // 1
      max-width: 1000px;
      width: 50%;
    }

    &--transparent {
      > img {
        background-color: transparent;
      }
    }
  }

  &--has-preview {
    cursor: pointer;
  }

  &--tiny {
    width: 100px;
  }

  &--small {
    width: 200px;
  }

  &--normal {
    width: 400px;
  }

  &--big {
    width: 600px;
  }

  &--full-width {
    width: 100%;
  }

  &--cover {
    height: 100%;
    object-fit: cover;
    object-position: left top;
    width: 100%;
  }

  &--has-border {
    border: 2px solid var(--csis_color);
  }

  &--transparent {
    background-color: transparent !important;
  }
}
