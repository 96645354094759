.toast {
  align-items: center;
  background-color: var(--card_background_color);
  border-radius: $radius_big;
  bottom: $spacing_huge;
  box-shadow: var(--elevation_shadow_1);
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  padding: $spacing_big;
  position: fixed;
  right: -633px;
  transition:
    right 0.9s,
    opacity 0.2s;
  z-index: $z_index_above_200;

  &__text {
    color: var(--csis_color);
    font-weight: $text_semibold;
    max-height: 300px;
    max-width: 30ch;
    overflow-y: auto;
  }

  &--shown {
    opacity: 1;
    right: $spacing_huge;
  }

  &--red {
    color: var(--red_color);

    .icon {
      background-color: var(--red_color);
    }

    .toast__text {
      color: var(--red_color);
    }
  }

  &--blue {
    color: var(--blue_color);

    .icon {
      background-color: var(--blue_color);
    }

    .toast__text {
      color: var(--blue_color);
    }
  }
}
