.list {
  border: var(--high_contrast_border_width) solid
    var(--high_contrast_border_color_subtle);
  border-radius: $radius_big;
  box-shadow: var(--elevation_shadow_1);
  box-sizing: border-box;
  display: inline-block; // this is so it can always grow as long as the widest child in a small parent component
  overflow: hidden;
  padding: 0;
  width: 100%;

  &--borderless {
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &__collapsable-group {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: $spacing_big $spacing_large;

    &__label {
      box-sizing: border-box;
      color: var(--app_font_color_secondary);
      cursor: pointer;
      font-weight: $text_semibold;
      min-width: 300px;
      width: 300px;

      .icon {
        background-color: var(--app_font_color_secondary);
      }

      &--big {
        min-width: 500px;
      }
    }

    &__value {
      cursor: pointer;
    }

    &--red {
      background-color: var(--red_color_transparent) !important;

      .list__collapsable-group__label {
        color: var(--red_color);

        .icon {
          background-color: var(--red_color);
        }
      }
    }

    &--grey {
      background-color: var(--grey_color_transparent) !important;

      .list__collapsable-group__label {
        color: var(--black_color_100);

        .icon {
          background-color: var(--black_color_100);
        }
      }
    }
  }

  &__item {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: auto;
    padding: $spacing_big $spacing_large;

    &__label {
      box-sizing: border-box;
      color: var(--app_font_color_secondary);
      font-weight: $text_semibold;
      min-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 300px;
    }

    &__value {
      box-sizing: border-box;
      overflow-wrap: anywhere;
      width: 100%;
    }
  }

  &.list--small {
    .list__item {
      padding: $spacing_normal $spacing_big;
    }

    .list__item__label {
      min-width: 210px;
      width: 210px;
    }
  }

  &.list--labelsize-small {
    .list__item__label {
      min-width: 180px;
      width: 180px;
    }
  }
}

.list__row:nth-child(even) {
  background-color: var(--list_bg_even);
}

.list__row:nth-child(odd) {
  background-color: var(--list_bg_odd);
}

.list__row--level-1 {
  padding-left: $text_gigantic;
}

.list__row--level-2 {
  padding-left: calc($text_gigantic + $text_large);
}

.list__row--level-3 {
  padding-left: calc($text_gigantic + calc(2 * $text_large));
}

.list--transparent {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  overflow: visible;

  .list__item {
    box-shadow: none;
    overflow: visible;
    padding: $spacing_normal 0;
  }

  .list__collapsable-group {
    padding: $spacing_normal 0;
  }

  .list__item__label {
    color: var(--app_font_color_secondary);
  }

  .list__row:nth-child(even) {
    background-color: transparent;
  }

  .list__row:nth-child(odd) {
    background-color: transparent;
  }

  .list__collapsable-group__label {
    color: var(--app_font_color_secondary);

    .icon {
      background-color: var(--app_font_color_secondary);
    }
  }

  .list__row--level-1 {
    padding-left: $text_large;
  }

  .list__row--level-2 {
    padding-left: calc($text_large + $text_large);
  }

  .list__row--level-3 {
    padding-left: calc($text_large + calc(2 * $text_large));
  }

  &.list--small {
    .list__item {
      padding: $spacing_tiny 0;
    }

    .list__item__label {
      min-width: 220px;
      width: 220px;
    }

    .list__row--level-1 {
      padding-left: $text_large;
    }

    .list__row--level-2 {
      padding-left: calc($text_large + $text_large);
    }

    .list__row--level-3 {
      padding-left: calc($text_large + calc(2 * $text_large));
    }
  }

  &.list--tiny {
    .list__item__label {
      min-width: 160px;
      width: 160px;
    }
  }
}

.list__item {
  @media (max-width: $mobile_screen_width) {
    font-size: $text_small;
  }
}

.list__item__label {
  @media (max-width: $mobile_screen_width) {
    min-width: 105px !important;
    width: 105px !important;
  }
}
