.landing-page {
  @media screen and (max-width: $large_screen_width) {
    max-width: 1100px;
  }

  &__tickets-overview {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .card-base;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: $spacing_huge;
  }

  &__articles-overview {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .card-base;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: $spacing_huge;

    &__article {
      background-color: var(--card_background_semitransparent);
      border-radius: $radius_small;
      box-sizing: border-box;
      padding: $spacing_big;
      width: 100%;
    }
  }

  &__alerts-overview {
    display: flex;
    height: 100%;
  }
}
