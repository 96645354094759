.codeblock {
  border-radius: $radius_small;
  box-sizing: border-box;
  cursor: text;
  display: inline-block;
  font-family: "Courier New", Courier, monospace;
  margin: 0;
  max-width: 100%;
  overflow-x: auto;
  padding: $spacing_tiny $spacing_small;
  position: relative;

  @media (max-width: $mobile_screen_width) {
    font-size: $text_small;
  }

  &--radius-big {
    border-radius: $radius_big;
  }

  pre {
    font-family: "Courier New", Courier, monospace;
    line-height: $text_large;
    margin: 0;
    overflow-x: auto;
    padding: $spacing_tiny $spacing_small;
  }

  &__actions {
    margin-left: $spacing_normal;
  }

  &--fullwidth {
    width: 100%;
  }

  &__container {
    display: flex;
  }

  &--red {
    background: var(--codeblock_bg_color);
    color: var(--codeblock_color);
  }

  &--grey {
    background: var(--codeblock_grey_bg_color);
    color: var(--codeblock_grey_color);
  }

  &--prewrap {
    pre {
      white-space: pre-wrap;
    }
  }

  &--multiline {
    min-height: 32px;
    min-width: 100px;

    pre {
      padding: $spacing_large;
    }

    .codeblock__actions {
      position: absolute;
      right: $spacing_small;
      top: $spacing_small;
    }
  }

  &--small {
    pre {
      font-size: $text_small;
      padding: $spacing_small;
    }
  }
}
