/*

1. the height of the simple skeleton should be equal to the size of a "normal" sized lowercase character
2. the extra margin on top+bottom is to imitate the line-height white space that normal text has

so now when a Skeleton is next to a "normal" text they should align perfectly

*/

.skeleton {
  background-color: var(--skeleton_background);
  border-radius: $radius_big;
  display: flex;
  height: $text_tiny; // 1
  margin-bottom: $spacing_normal; // 2
  margin-top: $spacing_normal; // 2
  overflow: hidden;
  position: relative;

  &--line {
    min-width: 8ch;
  }

  &--square {
    border-radius: $radius_normal;
    height: $spacing_gigantic_1;
    width: $spacing_gigantic_1;
  }

  &--circle {
    border-radius: $radius_circle;
    height: $text_gigantic;
    min-height: $text_gigantic;
    min-width: $text_gigantic;
    width: $text_gigantic;
  }

  &--image {
    border-radius: 0;
    height: 400px;
    min-height: 400px;
    min-width: 400px;
    width: 400px;
  }

  &--tile {
    border-radius: 0;
    height: 410px;
    min-height: 410px;
    min-width: 275px;
    width: 275px;
  }

  &--multiline-container {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: $spacing_small 0;
    width: 100%;

    .skeleton {
      margin-bottom: $spacing_normal;
    }

    .skeleton:last-child {
      margin-bottom: 0;
      width: 70%;
    }
  }

  &--chart-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    .skeleton__chart-shape {
      align-items: flex-end;
      display: flex;
      justify-content: center;
    }

    .skeleton {
      border-radius: $radius_small;
      margin-left: $text_big;
      margin-right: $text_big;
      width: $text_small;
    }

    .skeleton:nth-child(1) {
      height: 90px;
    }

    .skeleton:nth-child(2) {
      height: 130px;
    }

    .skeleton:nth-child(3) {
      height: 50px;
    }

    .skeleton:nth-child(4) {
      height: 20px;
    }
  }

  &::after {
    animation: shimmer 2s infinite;
    background-image: linear-gradient(
      90deg,
      rgba(#bbb, 0) 0,
      rgba(#bbb, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#bbb, 0)
    );
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
