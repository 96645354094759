.pill {
  align-items: center;
  background-color: var(--csis_color_transparent);
  border: 2px solid var(--csis_color);
  border-radius: $radius_gigantic;
  color: var(--pill_font_color);
  cursor: pointer;
  display: inline-flex;
  font-weight: $text_semibold;
  padding: $spacing_normal $spacing_big;

  span {
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    background-color: var(--pill_font_color);
    margin-left: $spacing_normal;
  }

  &--small {
    font-size: $text_small;
    padding: $spacing_small $spacing_normal;
  }
}
