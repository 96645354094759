.splitbutton {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  &__header {
    align-items: center;
    display: inline-flex;

    .button {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .button-icon {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-left: 1px;
      padding-left: $spacing_normal;
      padding-right: $spacing_normal;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--loading {
    .button-icon {
      cursor: not-allowed;
      opacity: $loading_opacity;
      pointer-events: none;
    }
  }
}
