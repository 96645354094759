.reminder {
  box-sizing: border-box;
  display: flex;
  font-weight: $text_semibold;
  padding: $spacing_normal $spacing_big;
  width: 100%;

  &--rounded-corners {
    border-radius: $radius_small;
  }

  &--underline-on-hover {
    &:hover div {
      text-decoration: underline;
    }
  }

  &--csis {
    background-color: var(--csis_color_transparent);
    color: var(--csis_color);

    .icon {
      background-color: var(--csis_color);
    }
  }

  &--red {
    background-color: var(--red_color_transparent);
    color: var(--red_color);

    .icon {
      background-color: var(--red_color);
    }
  }

  &--orange {
    background-color: var(--orange_color_transparent);
    color: var(--orange_color);

    .icon {
      background-color: var(--orange_color);
    }
  }

  &--blue {
    background-color: var(--blue_color_transparent);
    color: var(--blue_color);

    .icon {
      background-color: var(--blue_color);
    }
  }

  &--grey {
    background-color: var(--grey_color_transparent);
    color: var(--app_font_color_secondary);

    .icon {
      background-color: var(--app_font_color_secondary);
    }
  }
}
