.errorinfo {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  width: 100%;

  details {
    white-space: pre;
    width: 70%;
  }
}
