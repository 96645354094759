.breadcrumbs {
  color: var(--app_font_color_tertiary);
  font-weight: $text_semibold;

  .icon {
    background-color: var(--app_font_color_tertiary);
  }

  &__breadcrumb {
    color: var(--app_font_color_tertiary);

    .icon {
      background-color: var(--app_font_color_tertiary);
    }

    &:hover {
      color: var(--csis_color);

      .icon {
        background-color: var(--csis_color);
      }
    }

    &--active {
      color: var(--csis_color);
    }
  }

  &--small {
    font-size: $text_small;
  }

  &--normal {
    font-size: $text_normal;
  }

  &--big {
    font-size: $text_big;
  }
}
