.page {
  background: var(--app_background_color);
  border: var(--high_contrast_border_width) solid
    var(--high_contrast_border_color_subtle);
  border-radius: $radius_big;
  box-shadow: var(--elevation_shadow_1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: $spacing_big;
  padding: $spacing_huge $spacing_gigantic;
  width: 100%;

  &--no-padding {
    padding: 0;
  }

  @media (max-width: $mobile_screen_width) {
    padding: $spacing_large;
  }
}
