.stepper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  position: relative;

  &__step {
    color: var(--stepper_color);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;

    &--selected {
      color: var(--csis_color);
    }
  }

  &__content {
    align-items: center;
    border-right: 1px solid var(--divider_border);
    display: flex;
    flex-direction: row;
    font-weight: 500;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  &__step:last-child {
    .stepper__content {
      border-right: 0;
    }
  }

  &__number {
    font-size: $text_gigantic;
    margin-right: $spacing_huge;
  }

  &__text {
    font-size: $text_big;
    line-height: $text_big;
    max-width: 15ch;
  }

  &__footer {
    margin-top: $spacing_normal;
    text-align: center;
    width: 100%;
    z-index: $z_index_above_2; // 1
  }

  &__footer::before {
    border-bottom: 1px solid var(--stepper_color);
    bottom: 14px;
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
    z-index: $z_index_behind; // 2
  }

  &__circle {
    background-color: var(--stepper_color);
    border-radius: $radius_circle;
    display: inline-block;
    height: 18px;
    position: relative;
    width: 18px;
  }

  .stepper__step--selected {
    .stepper__circle {
      background-color: var(--csis_color);
    }
  }

  .stepper__step--completed {
    .stepper__circle {
      background-color: var(--stepper_color);
    }

    .stepper__circle::after {
      border: solid var(--app_background_color);
      border-width: 0 2px 2px 0;
      content: "";
      height: 8px;
      left: 7px;
      position: absolute;
      top: 3px;
      transform: rotate(45deg);
      width: 3px;
    }
  }
}

// 1 is important in combination with 2 to hide the line element behind the circle
