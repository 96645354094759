$min_dropdpown_height_possible: 170px;

$internal_dropdown_width: 250px;
$internal_dropdown_height: 200px;

.dropdown {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  &--full-width {
    display: flex;
    width: 100%;
  }

  &__header {
    align-items: center;
    border-radius: $radius_small;
    box-sizing: border-box;
    color: var(--csis_color);
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: $text_small;
    font-weight: $text_semibold;
    justify-content: space-between;
    line-height: $text_small;
    min-width: 100%;
    padding: $spacing_big;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      &__container {
        display: flex;
        max-width: 90%;
      }
    }

    .icon {
      background-color: var(--csis_color);
    }

    &:hover {
      color: var(--csis_color_lighter);

      .icon {
        background-color: var(--csis_color_lighter);
      }
    }
  }

  &__no-options {
    align-items: center;
    color: var(--disabled_font_color);
    display: flex;
    height: $min_dropdpown_height_possible;
    justify-content: center;
    min-width: $internal_dropdown_width;
    width: 100%;
  }

  &__list {
    &__check-all {
      border-bottom: 1px solid var(--disabled_font_color);
      display: flex;
      justify-content: space-between;
      padding: $spacing_big;

      .checkbox {
        margin-right: $spacing_huge;
        white-space: nowrap;
      }

      .dropdown__clear-selection {
        cursor: pointer;
        font-size: $text_small;
        font-weight: $text_regular;
      }
    }

    &__search {
      border-bottom: 1px solid var(--disabled_font_color);
      padding: $spacing_normal;
    }

    &__internal-dropdown {
      border-bottom: 1px solid var(--disabled_font_color);
      display: flex;
      justify-content: center;
      padding: $spacing_big;

      .listmenu__body {
        height: $internal_dropdown_height !important;

        max-height: $internal_dropdown_height !important;
        max-width: $internal_dropdown_width !important;

        min-height: $internal_dropdown_height !important;
        min-width: $internal_dropdown_width !important;

        width: $internal_dropdown_width !important;
      }
    }

    &__information-content {
      box-sizing: border-box;
      padding: $spacing_big;
    }

    &__check-btn {
      border-top: 1px solid var(--disabled_font_color);
      padding: $spacing_big;
    }
  }

  &--small {
    .dropdown__header {
      padding: $spacing_normal;
    }
  }

  &--uppercase {
    .dropdown__header__text {
      text-transform: uppercase;
    }
  }

  &.dropdown--blue {
    .dropdown__header {
      color: var(--blue_color);

      .icon {
        background-color: var(--blue_color);
      }

      &:hover {
        color: var(--blue_color_lighter);

        .icon {
          background-color: var(--blue_color_lighter);
        }
      }
    }
  }

  &.dropdown--red {
    .dropdown__header {
      color: var(--red_color);

      .icon {
        background-color: var(--red_color);
      }

      &:hover {
        color: var(--red_color_lighter);

        .icon {
          background-color: var(--red_color_lighter);
        }
      }
    }
  }

  &.dropdown--grey {
    .dropdown__header {
      color: var(--app_font_color_secondary);

      .icon {
        background-color: var(--app_font_color_secondary);
      }

      &:hover {
        color: var(--grey_color);

        .icon {
          background-color: var(--grey_color);
        }
      }
    }
  }
}

.dropdown--primary {
  .dropdown__header {
    background-color: var(--csis_color);
    border: $border_width_normal solid var(--csis_color);
    color: var(--app_background_color);

    .icon {
      background-color: var(--app_background_color);
    }

    &:hover {
      background-color: var(--csis_color_lighter);
      border-color: var(--csis_color_lighter);
      color: var(--app_background_color);

      .icon {
        background-color: var(--app_background_color);
      }
    }
  }

  &.dropdown--blue {
    .dropdown__header {
      background-color: var(--blue_color);
      border-color: var(--blue_color);
      color: var(--app_background_color);

      .icon {
        background-color: var(--app_background_color);
      }

      &:hover {
        background-color: var(--blue_color_lighter);
        border-color: var(--blue_color_lighter);
        color: var(--app_background_color);

        .icon {
          background-color: var(--app_background_color);
        }
      }
    }
  }

  &.dropdown--red {
    .dropdown__header {
      background-color: var(--red_color);
      border-color: var(--red_color);
      color: var(--app_background_color);

      .icon {
        background-color: var(--app_background_color);
      }

      &:hover {
        background-color: var(--red_color_lighter);
        border-color: var(--red_color_lighter);
        color: var(--app_background_color);

        .icon {
          background-color: var(--app_background_color);
        }
      }
    }
  }

  &.dropdown--grey {
    .dropdown__header {
      background-color: var(--sidebar_item_selected_color);
      border-color: var(--input_border_color);
      color: var(--app_font_color);

      .icon {
        background-color: var(--app_font_color);
      }

      &:hover {
        background-color: var(--grey-alt_color_lighter);
        border-color: var(--grey-alt_color_lighter);
        color: var(--app_font_color);

        .icon {
          background-color: var(--app_font_color);
        }
      }
    }
  }

  &.dropdown--disabled {
    .dropdown__header {
      background-color: var(--disabled_background_color);
      border-color: var(--disabled_background_color);
      color: var(--disabled_font_color);

      .icon {
        background-color: var(--disabled_font_color);
      }
    }
  }
}

.dropdown--shadow {
  .dropdown__header {
    background-color: var(--dropdown_background_color);
    border: 2px solid var(--input_border_color);
    box-shadow: var(--elevation_shadow_1);
    color: var(--app_font_color);
  }

  .dropdown__header:hover {
    border: 2px solid var(--csis_color);

    .icon {
      background-color: var(--csis_color);
    }
  }

  .icon {
    background-color: var(--app_font_color);
  }
}

.dropdown--disabled {
  cursor: not-allowed;
  pointer-events: none;

  .dropdown__header {
    background-color: var(--disabled_background_color);
    border-color: var(--disabled_background_color);
    color: var(--disabled_font_color);

    .icon {
      background-color: var(--disabled_font_color);
    }
  }
}

.dropdown--text {
  .dropdown__header {
    padding: $spacing_small 0;
  }

  &.dropdown--disabled {
    .dropdown__header {
      background-color: transparent;
      color: var(--disabled_font_color);

      .icon {
        background-color: var(--disabled_font_color);
      }
    }
  }
}
