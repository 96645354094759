.spinner {
  animation: s3 1s infinite linear;
  background: conic-gradient(
    rgba(255, 255, 255, 0) 10%,
    var(--app_background_color)
  );
  border-radius: 50%;
  height: $text_big;
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  width: $text_big;

  &--csis {
    background: conic-gradient(var(--csis_color), var(--app_background_color));
  }

  &--small {
    height: $text_normal;
    min-height: $text_normal;
    min-width: $text_normal;
    width: $text_normal;
  }

  &--big {
    height: $text_big;
    min-height: $text_big;
    min-width: $text_big;
    width: $text_big;
  }

  &--large {
    height: $text_large;
    min-height: $text_large;
    min-width: $text_large;
    width: $text_large;
  }
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
