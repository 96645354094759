// given a color and a percentage, it makes the color lighter by x%
@function makeLighter($color, $percentage) {
  @return scale-color($color, $lightness: $percentage);
}

// given a color and a percentage, it makes the color darker by x%
@function makeDarker($color, $percentage) {
  @return scale-color($color, $lightness: -$percentage);
}

// creates 4 css varaibles for a given color
@mixin createColorVariations($color, $color_label, $isHighContrast) {
  @if $isHighContrast {
    --#{$color_label}_darker: #{makeDarker($color, 14%)};
    --#{$color_label}_lighter: #{makeLighter($color, 14%)};
    --#{$color_label}_transparent: #{rgba($color, 0.025)};
  } @else {
    --#{$color_label}_darker: #{makeDarker($color, 11%)};
    --#{$color_label}_lighter: #{makeLighter($color, 11%)};
    --#{$color_label}_transparent: #{rgba($color, 0.1)};
    --#{$color_label}_semi-transparent: #{rgba($color, 0.6)};
  }
}

/**

Example input:
#c8191f,"red_color"

Example output:
  --red_color: #c8191f;
  --red_color_darker: #b4171c;
  --red_color_lighter: #e11c23;
  --red_color_transparent: rgba(200, 25, 31, 0.1);

*/
@mixin createColor($color, $color_label, $isHighContrast: false) {
  --#{$color_label}: #{$color};
  @include createColorVariations($color, $color_label, $isHighContrast);
}
