$z_index_dialog: $z_index_above_100;
$z_index_dialog_mask: $z_index_above_101;
$z_index_dialog_card: $z_index_above_102;
$dialog_width: 500px;
$dialog_width_small: 300px;
$dialog_width_big: 650px;
$dialog_width_large: 850px;

.dialog {
  box-sizing: border-box;
  position: static;
  z-index: $z_index_dialog;

  &__card {
    align-items: stretch;
    background-color: var(--dialog_background_color);
    border-radius: $radius_normal;
    box-shadow: var(--elevation_shadow_2);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: calc(100vh - #{$spacing_huge});
    max-width: 100%;
    min-width: $dialog_width;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: $dialog_width;
    z-index: $z_index_dialog_card;

    &--top {
      position: fixed;
      top: $spacing_big;
    }

    &--bottom {
      bottom: $spacing_big;
      position: fixed;
    }

    &--left {
      left: $spacing_big;
      position: fixed;
    }

    &--right {
      position: fixed;
      right: $spacing_big;
    }

    &--center {
      margin: 0 auto;
    }
  }

  &__header {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-weight: $text_semibold;
    justify-content: space-between;
    line-height: initial;
    padding: $spacing_big $spacing_large 0;

    &__title {
      font-size: $text_big;
      max-width: 40ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__subheader {
    box-sizing: border-box;
    padding: $spacing_big $spacing_large;
  }

  &__body {
    box-sizing: border-box;
    overflow-y: auto;
    padding: 0 $spacing_large $spacing_large $spacing_large;
    position: relative;

    &:focus-visible {
      outline-offset: 0;
    }
  }

  &__mask {
    align-items: center;
    background-color: var(--csis_color_transparent);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    min-height: 100vh;
    padding: $spacing_large;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z_index_dialog_mask;

    &--top {
      align-items: flex-start;
    }

    &--bottom {
      align-items: flex-end;
    }

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &--red {
    .dialog__mask {
      background-color: var(--red_color_transparent);
    }
  }

  &--blue {
    .dialog__mask {
      background-color: var(--blue_color_transparent);
    }
  }

  &--gradient {
    .dialog__card {
      background: var(--card_background_gradient_color);
    }
  }

  &--small {
    .dialog__card {
      max-width: $dialog_width_small;
      min-width: $dialog_width_small;
      width: $dialog_width_small;
    }
  }

  &--big {
    .dialog__card {
      max-width: $dialog_width_big;
      min-width: $dialog_width_big;
      width: $dialog_width_big;
    }
  }

  &--large {
    .dialog__card {
      max-width: $dialog_width_large;
      min-width: $dialog_width_large;
      width: $dialog_width_large;
    }
  }

  &--auto {
    .dialog__card {
      max-width: auto;
      min-width: auto;
      width: auto;
    }
  }

  &--spacing-none {
    .dialog__body {
      padding: 0;
    }
  }

  &--header-absolute {
    .dialog__header {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .dialog__card--with-header {
    .dialog__header {
      background-color: var(--csis_color);
      color: var(--app_background_color);
      padding: $spacing_big $spacing_large;
    }

    .dialog__body {
      padding: $spacing_large;
    }
  }

  @media (max-width: $mobile_screen_width) {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;

    .dialog__card {
      height: 100dvh;
      justify-content: flex-start;
      left: 0;
      max-height: 100dvh;
      max-width: 100%;
      min-height: 100dvh;
      min-width: 100%;
      top: 0;
      width: 100%;
    }

    .dialog__mask {
      padding: 0;
    }
  }
}
