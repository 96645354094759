.stepper-dialog {
  background-color: var(--app_background_color);
  box-sizing: border-box;
  display: flex;
  height: 77vh;
  min-height: 600px;
  width: 1150px;

  &__sidebar {
    background: var(--card_background_color);
    box-shadow: var(--elevation_shadow_1);
    box-sizing: border-box;
    height: 100%;
    min-width: 300px;
    overflow-y: scroll;
    padding: $spacing_large;
    width: 300px;

    &__item {
      border: 1px solid transparent;
      border-left: 3px solid transparent;
      border-radius: 3px 0 0 3px;
      box-sizing: border-box;
      color: var(--grey_color);
      cursor: pointer;
      display: flex;
      font-weight: $text_semibold;
      overflow: hidden;
      padding: $spacing_normal $spacing_big;
      width: 100%;

      &:hover {
        background-color: var(--sidebar_item_hover_color);
      }

      &--selected {
        background-color: var(--sidebar_item_selected_color);
        border: 1px solid var(--high_contrast_border_color);
        border-left: 3px solid var(--csis_color);
        color: var(--app_font_color);
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding-bottom: $spacing_large;
    padding-top: $spacing_large;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    overflow: scroll;
    padding: 0 $spacing_large;
  }

  &__actions {
    display: flex;
    padding: $spacing_big $spacing_large;
  }
}
