.csislogo {
  background-color: var(--sidebar_font_color);
  display: flex;
  flex-shrink: 0;
  height: 40.5px;
  mask: url("logo_white.svg");
  width: 100px;
  @media (max-width: $mobile_screen_width) {
    height: 30.5px;
    width: 75px;
  }
}
