@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 800;
  src:
    url("./fonts/Averta/Averta-Black.woff2") format("woff2"),
    url("./fonts/Averta/Averta-Black.woff") format("woff"),
    url("./fonts/Averta/Averta-Black.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: italic;
  font-weight: 800;
  src:
    url("./fonts/Averta/Averta-BlackItalic.woff2") format("woff2"),
    url("./fonts/Averta/Averta-BlackItalic.woff") format("woff"),
    url("./fonts/Averta/Averta-BlackItalic.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 600;
  src:
    url("./fonts/Averta/Averta-Bold.woff2") format("woff2"),
    url("./fonts/Averta/Averta-Bold.woff") format("woff"),
    url("./fonts/Averta/Averta-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: italic;
  font-weight: 600;
  src:
    url("./fonts/Averta/Averta-BoldItalic.woff2") format("woff2"),
    url("./fonts/Averta/Averta-BoldItalic.woff") format("woff"),
    url("./fonts/Averta/Averta-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 500;
  src:
    url("./fonts/Averta/Averta-Semibold.woff2") format("woff2"),
    url("./fonts/Averta/Averta-Semibold.woff") format("woff"),
    url("./fonts/Averta/Averta-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: italic;
  font-weight: 500;
  src:
    url("./fonts/Averta/Averta-SemiboldItalic.woff2") format("woff2"),
    url("./fonts/Averta/Averta-SemiboldItalic.woff") format("woff"),
    url("./fonts/Averta/Averta-SemiboldItalic.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-weight: 400;
  src:
    url("./fonts/Averta/Averta-Regular.woff2") format("woff2"),
    url("./fonts/Averta/Averta-Regular.woff") format("woff"),
    url("./fonts/Averta/Averta-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: normal;
  font-weight: 300;
  src:
    url("./fonts/Averta/Averta-Light.woff2") format("woff2"),
    url("./fonts/Averta/Averta-Light.woff") format("woff"),
    url("./fonts/Averta/Averta-Light.otf") format("opentype");
}

@font-face {
  font-family: "Averta";
  font-style: italic;
  font-weight: 300;
  src:
    url("./fonts/Averta/Averta-LightItalic.woff2") format("woff2"),
    url("./fonts/Averta/Averta-LightItalic.woff") format("woff"),
    url("./fonts/Averta/Averta-LightItalic.otf") format("opentype");
}
