$questionnaire_max_width: 600px;
$questionnaire_input_max_width: 400px;

.questionnaire {
  &__container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: $questionnaire_max_width;
    width: 100%;
    @media (max-width: $mobile_screen_width) {
      max-width: 100%;
    }

    .input {
      width: 100%;
    }
  }

  &__progressbar__container {
    margin-bottom: 30px;
    width: 100%;
  }

  &__questions__container {
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: $spacing_normal;
    width: $questionnaire_max_width;
    @media (max-width: $mobile_screen_width) {
      width: 100%;
    }
  }

  &__question-label {
    font-size: $text_small;
    font-weight: $text_semibold;
    max-width: $questionnaire_input_max_width;
  }
}
