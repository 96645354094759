.fi {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 1em;
  position: relative;
  width: 1.333333 * 1em;
}

@mixin flag-icon($country) {
  .fi-#{$country} {
    background-image: url(4x3/#{$country}.svg);
  }
}
