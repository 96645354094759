.help-and-support-page {
  &__menu-group {
    margin-left: $spacing_large;
  }
}

.apidocumentation {
  &__topic {
    margin-left: $spacing-big;
  }
}
