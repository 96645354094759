.ticket-page {
  &__description {
    &__title {
      font-size: $text_big;
      font-weight: $text_semibold;
    }

    &__container {
      padding: $spacing_big;
      width: 100%;

      /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
      @extend .card-base;
    }
  }
}

.comments-dialog {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .card-base;

  overflow: hidden;
  position: relative;
  width: 100%;

  &__comments {
    background-color: var(--app_background_color);
    max-height: 440px;
    overflow-y: scroll;
    padding: $spacing_large $spacing_big;
  }

  &__header {
    padding: $spacing_big;
  }

  &__input-comment {
    background-color: var(--card_background_color);
    padding: $spacing_big;
  }

  &__input-controls {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: $mobile_screen_width) {
      flex-direction: column;

      .inputfile {
        margin-bottom: $spacing_big;
      }
    }
  }
}
