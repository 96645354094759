.widgetcard {
  display: flex;
  width: 445px;

  &--flexgrow {
    flex-grow: 1;
  }

  &__header {
    background-color: var(--csis_color);
    border-radius: $radius_normal $radius_normal 0 0;
    box-sizing: border-box;
    color: var(--app_background_color);
    padding: $spacing_large $spacing_huge;
  }

  &__section {
    box-sizing: border-box;
    padding: $spacing_large $spacing_huge;

    &--full-height {
      height: 100%;
    }
  }

  &__chart {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      height: 300px; // recharts need a container to define width and height
      width: 95%; // recharts need a container to define width and height
    }
  }
}
