.draggable {
  box-sizing: border-box;
  cursor: move;
  display: flex; // if this ever changes remember to change the handler in draggable component

  &--inline {
    display: inline-flex;
  }

  &--full-width {
    width: 100%;
  }
}
