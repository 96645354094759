.notification-settings {
  &__header {
    align-items: center;
    background-color: var(--csis_color_transparent);
    border-radius: $radius_normal;
    box-sizing: border-box;
    display: flex;
    font-weight: $text_semibold;
    justify-content: space-between;
    padding: $spacing_normal $spacing_big;
    width: 100%;
  }

  &__side-elements {
    align-items: center;
    display: flex;

    &--header {
      padding-right: $spacing_big;
    }

    &__email {
      text-align: start;
      width: 100px;
    }

    &__severity {
      text-align: center;
      width: 250px;
    }

    &__fulltext {
      text-align: center;
      width: 180px;

      .checkbox {
        justify-content: center;
      }
    }
  }
}
