//always rules here on how to write atom css
//maybe move it to folder

// use it only to extend the .card
.card-base {
  background-color: var(--card_background_color);
  border: var(--high_contrast_border_width) solid
    var(--high_contrast_border_color_subtle);
  border-radius: $radius_big;
  box-shadow: var(--elevation_shadow_1);
  box-sizing: border-box;
}

.card {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .card-base;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &--radius-small {
    border-radius: $radius_small;
  }

  &--radius-normal {
    border-radius: $radius_normal;
  }

  &--radius-big {
    border-radius: $radius_big;
  }

  &--full-width {
    width: 100%;
  }

  &--full-height {
    height: 100%;
  }

  &--spacing-none {
    padding: 0;
  }

  &--spacing-normal {
    padding: $spacing_normal;
  }

  &--spacing-big {
    padding: $spacing_big;
  }

  &--spacing-large {
    padding: $spacing_large;
  }

  &--spacing-huge {
    padding: $spacing_huge;
  }

  &--align-space-between {
    justify-content: space-between;
  }

  &--hoverable:hover {
    background-color: var(--card_hover_color);
    cursor: pointer;
  }

  &--no-shadow {
    box-shadow: none;
  }
}

.card--csis {
  background-color: var(--csis_color);
  color: var(--app_background_color);

  &.card--hoverable:hover {
    background-color: var(--csis_color_darker);
  }
}

.card--alt {
  background-color: var(--card_background_alt_color);

  &.card--hoverable:hover {
    background-color: var(--grey_color);
  }
}

.card--alt-2 {
  background-color: var(--card_background_alt-2_color);

  &.card--hoverable:hover {
    background-color: var(--grey_color);
  }
}
