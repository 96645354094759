$base_chart_height: 600px;
$base_chart_height_fullscreen: 800px;
$base_chart_height_fullscreen_tall_screens: 1050px;

.threatcloud {
  width: 100%;
}

.threatcloud-graph-and-sidepanel-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  &--fullscreen {
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z_index_above_101;
  }
}

.threatcloud-container {
  position: relative;
  width: 80%;

  &--fullscreen {
    width: 70%;
  }
}

.threatcloud-sidepanel {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .card-base;
  background-color: var(--disabled_background_color);
  height: $base_chart_height;
  min-width: 340px;
  overflow: auto;
  padding: $spacing_big;
  width: 19%;

  &--fullscreen {
    box-shadow: var(--elevation_shadow_2);
    height: $base_chart_height_fullscreen;

    @media screen and (min-height: $tall_screen_height) {
      height: $base_chart_height_fullscreen_tall_screens;
    }
  }

  &__group {
    width: 100%;

    > div {
      margin-bottom: $spacing_small;
    }
  }

  &__node-list-item {
    cursor: pointer;
    font-weight: $text_semibold;
    word-wrap: anywhere;

    &:hover {
      color: var(--csis_color);
    }
  }

  &__node-entry-value {
    margin-left: $spacing_big;
  }
}

.threatcloud-graphcontainer {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .card-base;
  border: 0;
  box-sizing: border-box;
  height: $base_chart_height;
  width: 100%;

  &--fullscreen {
    box-shadow: var(--elevation_shadow_2);
    height: $base_chart_height_fullscreen;
    @media screen and (min-height: $tall_screen_height) {
      height: $base_chart_height_fullscreen_tall_screens;
    }
  }
}

.threatcloud-logo {
  bottom: $spacing_large;
  height: 47px;
  left: $spacing_large;
  position: absolute;
  width: 114px;

  path {
    fill: var(--disabled_background_color);
  }
}

.threatcloud-actions {
  box-sizing: border-box;
  height: 100%;
  padding: $spacing_big;
  position: absolute;
  right: 0;
  top: 0;
}

.threatcloud-svg {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;

  &:active {
    cursor: grabbing;
  }
}

// Nodes
.threatcloud-node {
  cursor: pointer;
  fill: var(--disabled_font_color);
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6));
  stroke: var(--app_font_color_secondary);
  stroke-width: 0;

  &--group {
    fill: var(--app_font_color_secondary);
    filter: drop-shadow(0 0 0);
  }

  &--selected {
    stroke: var(--csis_color);
    stroke-width: 2;
  }

  &--outlined {
    stroke: var(--csis_color_lighter);
    stroke-width: 2;
  }

  &--csis {
    fill: var(--csis_color);
  }

  &--csis.threatcloud-node--selected {
    stroke: var(--csis_color_lighter);
  }

  &--has-category {
    fill: var(--red_color);
  }

  &--has-category.threatcloud-node--selected {
    stroke: var(--red_color_lighter);
  }
}

.threatcloud-node-icon {
  cursor: pointer;
  fill: var(--app_font_color_secondary);

  &--central {
    fill: var(--app_background_color);
  }

  &--has-hidden {
    fill: var(--app_background_color);
  }
}

// Links
.threatcloud-link {
  cursor: pointer;
  stroke: var(--app_font_color_secondary);
}

// Texts
.threatcloud-text {
  fill: var(--app_font_color_secondary);
}

//Arrows
.threatcloud-arrow {
  cursor: pointer;
  fill: var(--app_font_color_secondary);
}
