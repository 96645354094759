.tickets-search-page {
  &__topbar {
    display: flex;
    @media (max-width: $mobile_screen_width) {
      display: none;
    }
  }

  &__topbar--mobile {
    display: none;
    @media (max-width: $mobile_screen_width) {
      display: flex;
    }
  }
}
