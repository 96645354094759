.switch {
  background-color: var(--disabled_font_color);
  border: $border_width_normal solid var(--disabled_font_color);
  border-radius: 15px;
  cursor: pointer;
  display: inline-flex;
  max-height: calc($text_normal + $spacing_tiny);
  padding: $spacing_tiny $spacing_large $spacing_tiny $spacing_tiny;
  position: relative;

  input {
    display: none;
  }

  &__slider {
    background-color: var(--card_background_color);
    border: 1px solid var(--card_background_color);
    border-radius: $radius_circle;
    height: $text_normal;
    width: $text_normal;
  }

  &--on {
    background-color: var(--csis_color);
    border-color: var(--csis_color);
    padding: $spacing_tiny $spacing_tiny $spacing_tiny $spacing_large;

    &:hover {
      background-color: var(--csis_color_lighter);
      border-color: var(--csis_color_lighter);
    }
  }

  &--middle {
    background-color: var(--csis_color);
    border-color: var(--csis_color);
    opacity: 0.6;
    padding: $spacing_tiny calc($spacing_large / 2 + 1px) $spacing_tiny
      calc($spacing_large / 2 + 1px);
  }

  &--disabled {
    background-color: var(--disabled_background_color);
    border: $border_width_normal solid var(--disabled_background_color);
    cursor: default;
  }
}
