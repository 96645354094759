.table-error {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 100%;
  position: relative;
  width: 100%;

  &__graphic {
    align-items: center;
    display: flex;
    height: 240px;
    justify-content: center;
    width: 240px;

    .icon {
      height: 200px;
      width: 200px;
    }
  }

  &__title-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  &__title {
    background-color: var(--csis_color_transparent);
    border-radius: $radius_tiny;
    padding: $spacing_normal $spacing_big;
  }
}
