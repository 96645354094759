@keyframes sideToside {
  0%,
  100% {
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(150%);
  }
}

.loading {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;

  img {
    width: 100px;
  }

  &__loader {
    background-color: var(--disabled_font_color);
    height: 2px;
    margin-top: 1em;
    overflow: hidden;
    position: relative;
    width: 100px;
  }

  &__bar {
    animation: sideToside 2s ease-in-out infinite;
    /*change this for the color of the sideToside line*/
    background-color: var(--csis_color);
    height: 100%;
    position: absolute;
    width: 50%;
  }
}
