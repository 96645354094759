.progress-bar {
  background-color: var(--disabled_font_color);
  border-radius: $radius_small;
  display: flex;
  height: $spacing_small;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__progress {
    background-color: var(--csis_color);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}
