.app-header {
  background: transparent;
  box-sizing: border-box;
  padding: $spacing_big 0 $spacing_big 0;
  width: 100%;
  z-index: $z_index_above_99;

  .page-title {
    opacity: 0;
  }

  &__accountmenu-element {
    padding: $spacing_big;

    &--first {
      border-bottom: 1px solid var(--disabled_font_color);
    }

    &--link {
      min-width: 180px;
      padding: $spacing_small;

      a {
        border-radius: $radius_small;
        box-sizing: border-box;
        color: var(--app_font_color);
        padding: $spacing_normal $spacing_big;
        width: 100%;

        &:hover {
          background-color: var(--grey-alt_color_semi-transparent) !important;
        }
      }
    }

    &--last {
      border-top: 1px solid var(--disabled_font_color);
    }
  }

  .app-header-right {
    justify-self: right;
    // this is so the page title is always "center" aligned perfectly
    width: 390px;
  }

  .global-search {
    // this is so the page title is always "center" aligned perfectly
    width: 390px;

    .input {
      border-radius: $radius_big;
    }
    @media (max-width: $mobile_screen_width) {
      width: 250px;

      .icon {
        display: none;
      }

      input {
        max-width: 190px;
        padding: $spacing_big $spacing_big;
      }
    }
  }

  @media (max-width: $mobile_screen_width) {
    background-color: var(--app_background_color);
    font-size: $text_normal;
    padding: calc($spacing_normal) calc($spacing_large);
    padding-right: 68px;

    .page-title {
      height: 0;
      width: 0;
    }
  }
}

.app-header--mobile-below {
  display: none;
  @media (max-width: $mobile_screen_width) {
    background-color: var(--app_background_color);
    box-shadow: var(--elevation_shadow_1);
    box-sizing: border-box;
    display: flex;
    font-size: $text_normal;
    justify-content: flex-start;
    left: 0;
    padding: calc($spacing_normal) calc($spacing_large);
    padding-right: 68px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z_index_above_99;

    .global-search {
      width: 250px;

      .input {
        border-radius: $radius_big;
      }

      .icon {
        display: none;
      }

      input {
        max-width: 190px;
        padding: $spacing_big $spacing_big;
      }
    }
  }
}
