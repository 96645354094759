.topbar {
  &__actions {
    align-items: center;
    display: flex;

    @media screen and (max-width: $large_screen_width) {
      align-items: end;
      flex-direction: column;
    }

    .button {
      margin-left: $spacing_big;
    }
  }
}
