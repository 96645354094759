.threat-insights {
  &__listview-toggle {
    position: absolute;
    right: $spacing_big;
    top: $spacing_big;
  }

  &__article {
    box-sizing: border-box;
    width: 100%;

    &__header:hover {
      > div:first-child {
        text-decoration: underline;
      }
    }

    &__desc {
      color: var(--app_font_color_secondary);
      max-width: 80ch;
      white-space: break-spaces;
    }
  }
}
