.pagination {
  align-items: stretch;
  display: inline-flex;
  flex-direction: row;
  position: relative;

  .button {
    margin-right: $spacing_normal;
    min-width: $spacing_huge;
  }

  .button-icon {
    margin-right: $spacing_normal;
  }
}
