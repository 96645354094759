.password-strength-indicator {
  display: flex;

  width: 100%;

  &__bar {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--red {
      .password-strength-indicator__quarter--solid {
        background-color: var(--red_color);
      }
    }

    &--orange {
      .password-strength-indicator__quarter--solid {
        background-color: var(--orange_color);
      }
    }

    &--green {
      .password-strength-indicator__quarter--solid {
        background-color: var(--green_color);
      }
    }
  }

  &__quarter {
    background-color: var(--disabled_font_color);
    height: 5px;
    width: 24%;
  }

  &__quarter--solid {
    height: 5px;
    width: 24%;
  }

  &__text {
    align-self: flex-end;

    &--red {
      color: var(--red_color);

      .icon {
        background-color: var(--red_color);
      }
    }

    &--orange {
      color: var(--orange_color);

      .icon {
        background-color: var(--orange_color);
      }
    }

    &--green {
      color: var(--green_color);

      .icon {
        background-color: var(--green_color);
      }
    }
  }
}
