.carousel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  position: relative;
  width: 100%;

  &__top-part {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  &__slides {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: $spacing_big;
    margin-right: $spacing_big;
  }

  &__control {
    align-self: center;
  }

  &__footer {
    display: relative;
  }

  &__navigation {
    display: flex;
    justify-content: center;
    margin-top: $spacing_big;

    li {
      display: flex;
    }

    &__button {
      background-color: var(--app_font_color_tertiary);
      border-color: var(--app_font_color_tertiary);
      border-radius: $radius_circle;
      border-style: none;
      cursor: pointer;
      height: $spacing_normal;
      margin-left: $spacing_small;
      margin-right: $spacing_small;
      max-height: $spacing_normal;
      max-width: $spacing_normal;
      min-height: $spacing_normal;
      min-width: $spacing_normal;
      padding: 0;
      width: $spacing_normal;

      &:hover {
        background-color: var(--csis_color);
        border-color: var(--csis_color);
      }

      &:active {
        background-color: var(--csis_color);
        border-color: var(--csis_color);
      }

      &--current {
        background-color: var(--csis_color);
        border-color: var(--csis_color);
      }
    }

    &__autoplay {
      bottom: 0;
      max-height: fit-content;
      position: absolute;
      right: 0;

      .icon {
        align-self: center;
      }
    }
  }
}
