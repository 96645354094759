// 1 because of the tooltip overflowing, the heatcalendar cannot have overflow: scroll
// that means its meant to be used in places where the width can be its max (around 900px for a whole year)

.heatcalendar {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: visible; // 1;
    width: 100%;
  }

  &__tooltip {
    background-color: var(--app_font_color);
    border-radius: $radius_normal;
    color: var(--app_background_color);
    left: -107px;
    opacity: 0.8;
    padding: $spacing_normal $spacing_big;
    position: absolute;
    text-align: center;
    top: 30px;
    visibility: hidden;
    width: 200px;
    z-index: 2;
  }

  &__tooltip-image-container {
    max-height: 150px;
    overflow: hidden;
    width: 100%;

    > img {
      background-color: #fafafa;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__month-labels {
    display: inline-flex;
    height: 20px;
    margin-left: 4px;
    position: relative;
  }

  &__month-label {
    font-size: 14px;
    position: absolute;
  }

  &__all-weeks {
    display: inline-flex;
  }

  &__week {
    display: flex;
    flex-direction: column;

    &--first {
      justify-content: flex-end;
    }
  }

  &__day {
    background: var(--grey-alt_color);
    border-radius: 2px;
    margin: 1.5px;

    &:hover + .heatcalendar__tooltip {
      visibility: visible;
    }
  }

  &__day-with-content {
    background: var(--heatcalendar_default_square_fill_color);
    cursor: pointer;

    &.day-lvl1 {
      background: var(--heatcalendar_lvl1_square_fill_color);
    }

    &.day-lvl2 {
      background: var(--heatcalendar_lvl2_square_fill_color);
    }

    &.day-lvl3 {
      background: var(--heatcalendar_lvl3_square_fill_color);
    }

    &.day-lvl4 {
      background: var(--heatcalendar_lvl4_square_fill_color);
    }

    &--selected {
      background: var(--heatcalendar_default_square_fill_color);
      border: 2px solid var(--app_font_color);
      border-radius: 3px;
      cursor: pointer;
      margin: -0.5px;
    }
  }

  &__legend-container {
    display: flex;
    justify-content: flex-end;
    width: 1000px;
  }

  &__legend {
    align-items: center;
    display: flex;
  }
}
